import { DataGrid, frFR, GridColDef } from '@mui/x-data-grid'
import { useTranslation } from '@osrdata/app_core/dist/translation'
import FeedbackSnackBar from 'components/Common/FeedbackSnackBar/FeedbackSnackBar'
import { ReactElement, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import GrefServices from 'services/GrefServices'
import { RootState } from 'Store'
import columnsHeader from './const'
import './SheetReaccord.scss'

export default function SheetReaccord(): ReactElement {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [columns, setColumns] = useState<GridColDef[]>([])
  const { newExport, reaccordList, reaccordLoading } = useSelector((state: RootState) => state.grefs)

  useEffect(() => {
    const cols = columnsHeader.map(({ headerName, ...col }) => ({
      ...col,
      headerName: t(`TreatRejects.table.${headerName}`),
    }))
    setColumns(cols)
  }, [])

  useEffect(() => {
    dispatch(GrefServices.getExportDetails(newExport.id))
  }, [newExport])

  return (
    <div className="summary-container">
      <div className="d-flex flex-grow-1 justify-content-center">
        <DataGrid
          loading={reaccordLoading}
          rowHeight={60}
          rows={reaccordList.rows}
          columns={columns}
          className="summary-table-container"
          headerHeight={60}
          localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
          hideFooter
          disableColumnFilter
          disableColumnMenu
        />
      </div>
      <FeedbackSnackBar />
    </div>
  )
}
