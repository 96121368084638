import { MouseEvent, ReactElement, useState } from 'react'
import { useSelector } from 'react-redux'

import Menu from '@mui/material/Menu'
import Button from '@mui/material/Button'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import { useTranslation } from '@osrdata/app_core/dist/translation'

import { RootState } from 'Store'
import { MenuItem } from '@mui/material'
import ACCOUNT_MENU_ITEMS from './accountMenuItems'

export default function AccountMenu(): ReactElement {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const user = useSelector((state: RootState) => state.user)
  const isMenuOpen = Boolean(anchorEl)
  const { t } = useTranslation()

  const handleButtonClick = (e: MouseEvent<HTMLElement>) => {
    setAnchorEl(e.currentTarget)
  }

  const handleMenuClose = (next?: () => void) => {
    setAnchorEl(null)
    if (next !== undefined) next()
  }

  return (
    <div>
      <Button onClick={handleButtonClick} endIcon={<KeyboardArrowDownIcon />}>
        {`${user.account.firstName} ${user.account.lastName}`}
      </Button>
      <Menu open={isMenuOpen} anchorEl={anchorEl} onClose={() => handleMenuClose()}>
        {ACCOUNT_MENU_ITEMS().map(item => (item.show && (
          <MenuItem key={item.label} onClick={() => handleMenuClose(item.onClick)}>
            {item.icon && (
              <div className="mr-2">
                {item.icon}
              </div>
            )}
            <div className="menu-item-title">
              {t(item.label)}
            </div>
          </MenuItem>
        )))}
      </Menu>
    </div>
  )
}
