/* eslint-disable react/no-array-index-key */
/* eslint-disable camelcase */
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import {
  Accordion, AccordionDetails, AccordionSummary, List,
} from '@mui/material'
import ListItem from '@mui/material/ListItem'
import { SheetInfo } from 'objects/types'
import {
  ReactElement, SyntheticEvent, useEffect, useRef, useState,
} from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import { useDispatch, useSelector } from 'react-redux'
import { EicState } from 'reducers/eics'
import {
  GrefState, setIndex, setPageNumber,
} from 'reducers/grefs'
import GrefServices from 'services/GrefServices'
import { RootState } from 'Store'
import Loader from '../Loader'
import './ObjectsList.scss'

type Props = {
  items: SheetInfo[];
}

export default function ObjectsList({ items }: Props): ReactElement {
  const dispatch = useDispatch()
  const ref = useRef<HTMLDivElement>(null)
  const {
    sheetCount, filters, currentIndex, pageNumber, sheetsLoading, currentReject,
  } = useSelector((state: RootState): GrefState => state.grefs)
  const { currentEic } = useSelector((state: RootState) => state.eics) as EicState
  const [hasMore, setHasMore] = useState(true)
  const [hide, setHide] = useState(false)

  const getNext = () => {
    if (items.length >= Number(sheetCount)) {
      setHasMore(false)
      return
    }
    dispatch(GrefServices.getSheets({ eic: currentEic, page: pageNumber + 1, filterItems: filters }))
    setPageNumber(pageNumber + 1)
  }

  useEffect(() => {
    if (hide) {
      setHide(false)
    }
    setHide(false)
  }, [currentIndex.reject])

  useEffect(() => {
    if (hide) {
      setHide(false)
      return
    }
    const sheetId = items[currentIndex.sheet]?.id
    const btn = document.getElementById(sheetId)
    if (btn && ref.current) {
      ref.current.scrollTo(0, btn.offsetTop)
    }
  }, [currentIndex.sheet])

  const handleChangeSheet = (index: number) => {
    if (index === currentIndex.sheet) {
      setHide(!hide)
      return
    }
    dispatch(setIndex({ sheet: index, reject: 0 }))
  }

  const changeReject = (index: number, event: SyntheticEvent) => {
    event.stopPropagation()
    dispatch(setIndex({ sheet: currentIndex.sheet, reject: index }))
  }

  return (
    <div
      className={`d-flex sheet-button-wrapper flex-wrap w-100 ${filters.length > 0
        && filters.some(elem => elem.value) ? 'with-filter' : ''}`}
      ref={ref}
      id="scrollableDiv"
    >
      {sheetsLoading && items.length === 0 ? <Loader /> : (
        <List>
          <InfiniteScroll
            dataLength={items.length}
            next={getNext}
            hasMore={hasMore}
            loader={<></>}
            scrollableTarget="scrollableDiv"
          >

            {items.map((item, ind) => (
              <ListItem
                tabIndex={0}
                id={item.id}
                key={item.id}
              >
                <Accordion
                  id={`${ind}`}
                  onClick={() => handleChangeSheet(ind)}
                  expanded={ind === currentIndex.sheet && !hide}
                  className={`w-100 ${ind === currentIndex.sheet && !hide ? 'sheet-button' : 'sheet-button-inactive'}`}
                >
                  <AccordionSummary
                    className="sheet-accordion-summary"
                    expandIcon={<KeyboardArrowDownIcon />}
                    aria-controls="panel1a-content"
                  >
                    <span className="title">{`${item.serial}\u00A0`}</span>
                    <span className="subtitle">{item.week}</span>
                  </AccordionSummary>
                  <AccordionDetails className="sheet-accordion-details">
                    {items[currentIndex.sheet]?.rejects?.map((info, index) => (
                      <div
                        tabIndex={0}
                        role="button"
                        id={info.id}
                        onClick={event => changeReject(index, event)}
                        title={info.protection_name}
                        className={`sheet-related-protection
                        ${info.id === currentReject.id ? 'dp-active' : 'dp-inactive'}`}
                        key={index}
                      >
                        {info.protection_name}
                      </div>
                    ))}
                  </AccordionDetails>
                </Accordion>
              </ListItem>
            ))}
          </InfiniteScroll>
        </List>
      )}
    </div>
  )
}
