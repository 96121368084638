import { ReactElement, useEffect, useState } from 'react'

import Select, { SelectChangeEvent } from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import './CustomSelect.scss'
import { FormControl } from '@mui/material'

type SelectItem = {
  label: string;
  value: string;
}

type Props = {
  value: string | SelectItem;
  items: Array<string | SelectItem>;
  onBlur?: (newValue: unknown) => void;
  onOpen?: () => void;
  onChange?: (newValue: string) => void;
} & typeof defaultProps

const defaultProps = {
  onBlur: (_input: unknown) => { },
  onChange: (_value: string) => { },
  onOpen: () => { },
}

export default function CustomSelect({
  value, items, onBlur, onChange, onOpen,
}: Props): ReactElement | null {
  const [selected, setSelected] = useState<string | SelectItem>(value)

  useEffect(() => {
    setSelected(value)
  }, [])

  useEffect(() => {
    setSelected(value)
  }, [value])

  const handleChange = (event: SelectChangeEvent<string | SelectItem>) => {
    const newValue = event.target.value
    setSelected(newValue)
    if (typeof newValue === 'string') {
      onChange(newValue)
    } else {
      onChange(newValue.value)
    }
  }

  return (
    <FormControl fullWidth variant="standard">
      <Select
        onChange={handleChange}
        onOpen={onOpen}
        disableUnderline
        value={selected || ''}
        className="select"
        onBlur={() => onBlur(selected)}
      >

        {items.map(item => {
          if (typeof item === 'string') {
            return (
              <MenuItem key={item} value={item}>
                {item}
              </MenuItem>
            )
          }
          return (
            <MenuItem key={item.value} value={item.value}>
              {item.label}
            </MenuItem>
          )
        })}

      </Select>
    </FormControl>
  )
}

CustomSelect.defaultProps = defaultProps
