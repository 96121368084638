import { ReactElement } from 'react'
import TextField from '@mui/material/TextField'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined'
import './CustomDatePicker.scss'
import { DateTime } from 'luxon'

type Props = {
  value: DateTime | null;
  onChange: (v: DateTime | null) => void;
}
export default function CustomDatePicker({ value, onChange }: Props): ReactElement {
  return (
    <div className="date-picker">
      <DatePicker
        label=" "
        value={value}
        onChange={newDate => onChange(newDate)}
        components={{
          OpenPickerIcon: CalendarTodayOutlinedIcon,
        }}
        renderInput={params => (
          <TextField
            {...params}
            variant="outlined"
            InputLabelProps={{ shrink: false }}
            InputProps={{ ...params.InputProps, className: 'input-date' }}
          />
        )}
      />
    </div>
  )
}
