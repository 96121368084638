import { InputBase, Paper } from '@mui/material'
import {
  ChangeEvent, KeyboardEvent, ReactElement, ReactNode, useEffect, useState,
} from 'react'
import './CustomTextField.scss'

type Props = {
  value: string;
  type?: string;
  onBlur?: (newValue: unknown) => void;
  onChange?: (newValue: string) => void;
  error?: boolean;
  maxLength?: number;
  endAdornment?: ReactNode;
} & typeof defaultProps

const defaultProps = {
  onBlur: (_input: unknown) => { },
  onChange: (_value: string) => { },
  error: false,
  maxLength: Infinity,
  type: 'string',
  endAdornment: <span />,
}

export default function CustomTextField({
  value, onBlur, onChange, error, maxLength, endAdornment, type,
}: Props): ReactElement {
  const [input, setVal] = useState('')

  useEffect(() => {
    const val = value === null || value === undefined ? '' : value
    setVal(val)
  }, [value])

  const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (maxLength === undefined || e.target.value.length <= maxLength) {
      if (type === 'number') {
        const intVal = (e.target.value).replace(/[^0-9]/g, '')
        setVal(intVal)
        onChange(intVal)
      } else {
        setVal(e.target.value)
        onChange(e.target.value)
      }
    }
  }

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement | HTMLTextAreaElement> | undefined) => {
    if (event) {
      event.stopPropagation()
      if (event.key === 'Enter' || event.key === 'Tab') {
        const target = event.target as HTMLInputElement
        event.preventDefault()
        target.blur()
      }
    }
  }

  return (
    <Paper
      elevation={0}
      component="form"
      className={`${error ? 'textfield-error' : ''}`}
      sx={{
        p: '2px 4px', display: 'flex', alignItems: 'center', width: '100%',
      }}
    >
      <InputBase
        inputProps={{ maxLength }}
        className="textfield"
        onChange={handleChange}
        sx={{ flex: 1 }}
        value={input}
        error={error}
        onBlur={() => onBlur(input)}
        onKeyDown={e => handleKeyDown(e)}
        endAdornment={endAdornment}
      />
    </Paper>
  )
}

CustomTextField.defaultProps = defaultProps
