type color = 'error' | 'default' | 'success' | 'info' | 'warning' | 'primary' | 'secondary' | undefined

const colors: { [key: string]: color } = {
  failed: 'error',
  pending: 'warning',
  imported: 'success',
}

const statusList = {
  success: 'imported',
  pending: 'pending',
  fail: 'failed',
}

export { colors, statusList }
