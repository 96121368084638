import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Eic } from 'objects/types'
import EicServices from 'services/EicServices'

export interface EicState {
  loading: boolean;
  eics: Eic[];
  currentEic: string;
}

const initialState: EicState = {
  loading: false,
  eics: [],
  currentEic: '',
}

export const eicsSlices = createSlice({
  name: 'eics',
  initialState,
  reducers: {
    setEic: (state, action: PayloadAction<string>) => {
      state.currentEic = action.payload
    },
  },
  extraReducers: builder => {
    builder.addCase(EicServices.getAll.fulfilled, (state, action) => {
      state.loading = false
      state.eics = action.payload
      state.currentEic = action.payload[0].slug.toLowerCase()
    })
    builder.addCase(EicServices.getAll.pending, state => {
      state.loading = true
    })
  },
})

export const {
  setEic,
} = eicsSlices.actions

export default eicsSlices.reducer
