/* eslint-disable camelcase */
import { Backdrop, Pagination } from '@mui/material'
import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import { useTranslation } from '@osrdata/app_core/dist/translation'
import InfoPanel, { Severity } from 'components/Common/InfoPanel/InfoPanel'
import Loader from 'components/Common/Loader'
import ImportFile from 'components/ImportFile/ImportFile'
import {
  ChangeEvent, ReactElement, useEffect, useRef, useState,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { EicState } from 'reducers/eics'
import { GrefState } from 'reducers/grefs'
import GrefServices from 'services/GrefServices'
import UserServices from 'services/UserServices'
import { User } from 'services/UserServices/cerbereTypes'
import { RootState } from 'Store'
import { COLUMNS } from './const'
import './ImportHome.scss'

export default function ImportHome(): ReactElement {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const tableRef = useRef<HTMLTableElement>(null)
  const { grefs, grefsLoading, statusLoading } = useSelector((state: RootState) => state.grefs) as GrefState
  const { currentEic } = useSelector((state: RootState) => state.eics) as EicState
  const [page, setPage] = useState(1)
  const { count, page_size } = useSelector((state: RootState) => state.grefs)
  const userList = useSelector((state: RootState) => state.grefs.userList) as User[]
  const pagesNumber = Math.ceil(count / page_size) || 1

  useEffect(() => {
    const usersId: string[] = []
    grefs.forEach(gref => {
      if (usersId.indexOf(gref.imported_by) === -1) {
        usersId.push(gref.imported_by)
      }
    })
    dispatch(UserServices.getUsersListInfo(usersId))
  }, [grefs])

  useEffect(() => {
    if (page === 1) {
      dispatch(GrefServices.getAll({ eic: currentEic, page }))
    } else {
      setPage(1)
    }
  }, [currentEic])

  useEffect(() => {
    dispatch(GrefServices.getAll({ eic: currentEic, page }))
  }, [page])

  const handleChangePage = (_event: ChangeEvent<unknown> | null, newPage: number) => {
    if (tableRef.current) {
      tableRef.current.scrollTo(0, 0)
    }
    setPage(newPage)
  }

  const renderData = () => {
    if (grefsLoading) {
      return (
        <div
          style={{ minHeight: '65vh', maxHeight: '65vh' }}
          className=" d-flex justify-content-center align-items-center"
        >
          <Loader />
        </div>
      )
    }
    if (grefs.length > 0) {
      return (
        <TableContainer
          ref={tableRef}
          className="table-container"
          sx={{ minWidth: 650, height: 'calc(100vh - 300px)' }}
        >
          <Table stickyHeader aria-label="simple table">
            <colgroup>
              <col style={{ width: '30%' }} />
              <col style={{ width: '10%' }} span={2} />
              <col style={{ width: '20%' }} />
              <col style={{ width: '20%' }} />
              <col style={{ width: '10%' }} />
            </colgroup>
            <TableHead>
              <TableRow>
                {COLUMNS.map(header => (
                  <TableCell
                    key={header.title}
                    align={header.align}
                  >
                    {t(header.title)}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {(grefs).map(gref => (
                <TableRow
                  key={gref.id}
                >

                  {COLUMNS.map(column => (
                    <TableCell key={`${gref.id}-${column.title}`} align={column.align}>
                      {column.formatter(gref[column.propertyName], userList, page)}
                    </TableCell>
                  ))}
                </TableRow>
              ))}

            </TableBody>
          </Table>
        </TableContainer>
      )
    }
    return (
      <InfoPanel
        title={t('importRejects.noImport.title')}
        subtitle={t('importRejects.noImport.subtitle')}
        severity={Severity.warning}
      />
    )
  }
  return (
    <Paper className="main-container">
      <ImportFile />
      <Backdrop style={{ zIndex: '200', backgroundColor: '#ffffffe8' }} open={statusLoading}>
        <Loader message={t('importRejects.pending.import')} />
      </Backdrop>
      {renderData()}
      <div className="d-flex footer align-items-end justify-content-center">
        <Pagination
          onChange={handleChangePage}
          variant="outlined"
          page={page}
          shape="rounded"
          boundaryCount={1}
          siblingCount={1}
          count={pagesNumber}
          disabled={grefsLoading}
        />
      </div>
    </Paper>
  )
}
