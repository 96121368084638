import SquareIcon from '@mui/icons-material/Square'
import { IconButton } from '@mui/material'
import { Reject } from 'objects/types'
import { MouseEvent, ReactElement } from 'react'
import { batch, useDispatch, useSelector } from 'react-redux'
import { GrefState, resetMouseEvent, setMouseDown } from 'reducers/grefs'
import GrefServices from 'services/GrefServices'
import { RootState } from 'Store'
import CustomSelect from '../Inputs/CustomSelect/CustomSelect'
import './CustomDraggableCell.scss'

type SelectItem = {
  label: string;
  value: string;
}

type Props = {
  reject: Reject;
  items: Array<string | SelectItem>;
  onBlur?: (newValue: unknown) => void;
  onOpen?: () => void;
  onChange?: (newValue: string) => void;
} & typeof defaultProps

const defaultProps = {
  onBlur: (_input: unknown) => { },
  onChange: (_value: string) => { },
  onOpen: () => { },
}

export default function CustomDraggableCell({
  reject, items, onBlur, onOpen, onChange,
}: Props): ReactElement {
  const dispatch = useDispatch()
  const { mouseEventAnalysis } = useSelector((state: RootState): GrefState => state.grefs)

  const handlePress = (event: MouseEvent<HTMLButtonElement>, rej: Reject) => {
    const coords = (event.currentTarget?.parentNode?.parentNode as HTMLElement).getBoundingClientRect()
    dispatch(setMouseDown({
      ...mouseEventAnalysis, mouseDown: true, mouseUp: false, reject: rej, y: coords.y,
    }))
  }

  const handleUp = () => {
    batch(() => {
      mouseEventAnalysis.rejectsToChange.forEach(rej => {
        dispatch(GrefServices.updateReject({ ...rej, current_analysis: mouseEventAnalysis.reject.current_analysis }))
      })
    })
    dispatch(resetMouseEvent())
  }

  const mouseEnter = (event: MouseEvent<HTMLDivElement>, currentReject: Reject) => {
    const msa = mouseEventAnalysis
    if (msa.mouseDown && msa.reject?.current_analysis !== '') {
      const coords = (event.currentTarget?.parentNode?.parentNode as HTMLElement).getBoundingClientRect()
      if (msa.y < coords.y) {
        if ([null, 'down'].includes(msa.direction)) {
          dispatch(setMouseDown({
            ...msa,
            rejectsToChange: [...msa.rejectsToChange, currentReject],
            y: coords.y,
            direction: 'down',
          }))
        } else {
          const len = msa.rejectsToChange.length
          const newDirection = len === 1 ? null : 'up'
          dispatch(setMouseDown({
            ...msa,
            y: coords.y,
            rejectsToChange: msa.rejectsToChange.slice(0, len - 1),
            direction: newDirection,
          }))
        }
      } else {
        if ([null, 'up'].includes(msa.direction)) {
          dispatch(setMouseDown({
            ...msa,
            rejectsToChange: [...msa.rejectsToChange, currentReject],
            y: coords.y,
            direction: 'up',
          }))
          return
        }

        const len = msa.rejectsToChange.length
        const newDirection = len === 1 ? null : 'down'
        dispatch(setMouseDown({
          ...msa,
          y: coords.y,
          rejectsToChange: msa.rejectsToChange.slice(0, len - 1),
          direction: newDirection,
        }))
      }
    }
  }

  return (
    <div
      tabIndex={0}
      role="button"
      onMouseUp={handleUp}
      onMouseEnter={e => mouseEnter(e, reject)}
    >
      <CustomSelect
        value={reject.current_analysis}
        items={items}
        onBlur={onBlur}
        onOpen={onOpen}
        onChange={onChange}
      />
      <IconButton
        className="draggable-button"
        onMouseDown={e => handlePress(e, reject)}
        onMouseUp={handleUp}
        size="small"
      >
        <SquareIcon />
      </IconButton>
    </div>
  )
}

CustomDraggableCell.defaultProps = defaultProps
