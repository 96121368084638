import {
  IconButton, List, ListItem, ListItemButton, ListItemText, Popover,
} from '@mui/material'
import { MouseEvent, ReactElement, useState } from 'react'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import './CustomMenu.scss'
import { useTranslation } from '@osrdata/app_core/dist/translation'
import { useDispatch, useSelector } from 'react-redux'
import {
  GrefState, addFilter, setFilterMenu, setPinnedColumn, removePinnedColumn,
} from 'reducers/grefs'
import { GridOperator } from 'services/GrefServices'
import { RootState } from 'Store'

type Props = {
  column: string;
  visible: string;
  setMenu: (e: string | null) => void;
}
export default function CustomMenu({ column, visible, setMenu }: Props): ReactElement {
  const { t } = useTranslation()
  const { filters, pinnedColumns } = useSelector((state: RootState): GrefState => state.grefs)
  const dispatch = useDispatch()
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setMenu(column)
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setMenu(null)
    setAnchorEl(null)
  }

  const handleFilter = () => {
    handleClose()
    if (filters.find(filter => filter.columnField === column)) {
      dispatch(setFilterMenu(true))
      return
    }
    const filterId = filters.length ? (filters[filters.length - 1].id as number) + 1 : 0
    dispatch(addFilter({
      id: filterId,
      columnField: column,
      operatorValue: GridOperator.contains,
      value: '',
    }))
    dispatch(setFilterMenu(true))
  }

  const handlePin = (col: string) => {
    handleClose()
    dispatch(setPinnedColumn(col))
  }
  const handleUnPin = (col: string) => {
    handleClose()
    dispatch(removePinnedColumn(col))
  }
  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined

  return (
    <div className={visible === column ? 'active-menu-icon' : 'inactive-menu-icon'}>
      <IconButton sx={{ outline: 'none !important' }} onClick={handleClick}>
        <MoreVertIcon />
      </IconButton>
      <Popover
        id={id}
        open={open}
        sx={{ zIndex: 1600 }}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <div className="table-menu">
          <List>
            <ListItem disablePadding>
              <ListItemButton onClick={handleFilter}>
                <ListItemText primary={t('TreatRejects.menu.filter')} />
              </ListItemButton>
            </ListItem>
            {pinnedColumns.includes(column) ? (
              <ListItem disablePadding>
                <ListItemButton onClick={() => handleUnPin(column)}>
                  <ListItemText primary={t('TreatRejects.menu.unPinColumn')} />
                </ListItemButton>
              </ListItem>
            ) : (
              <ListItem disablePadding>
                <ListItemButton onClick={() => handlePin(column)}>
                  <ListItemText primary={t('TreatRejects.menu.pinColumn')} />
                </ListItemButton>
              </ListItem>
            )}
          </List>
        </div>
      </Popover>
    </div>
  )
}
