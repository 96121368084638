import {
  ChangeEvent, ReactElement, useEffect,
} from 'react'
import { useTranslation } from '@osrdata/app_core/dist/translation'
import UploadButton, { ButtonStyle } from 'components/Common/UploadButton/UploadButton'
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined'
import './ImportFile.scss'
import GrefServices from 'services/GrefServices'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'Store'
import FeedbackSnackBar from 'components/Common/FeedbackSnackBar/FeedbackSnackBar'
import { toggleSendFile } from 'reducers/grefs'
import { EicState } from 'reducers/eics'

export default function ImportFile(): ReactElement {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { created, statusLoading, taskStatus } = useSelector((state: RootState) => state.grefs)
  const { currentEic } = useSelector((state: RootState) => state.eics) as EicState

  const onSelectedFile = (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault()

    const file = e.target.files
    if (file) {
      const formData = new FormData()
      formData.append('file', file[0])
      formData.append('filename', file[0].name)
      const object = { formData }
      dispatch(toggleSendFile())
      dispatch(GrefServices.addGref(object))
      e.target.value = ''
    }
  }

  useEffect(() => {
    if (created.task_uuid && (!taskStatus.status || created.task_uuid !== taskStatus.task_uuid)) {
      dispatch(GrefServices.getStatus(created.task_uuid))
    }
  }, [created])

  useEffect(() => {
    if (taskStatus.task_uuid && taskStatus.status === 'PENDING') {
      setTimeout(() => dispatch(GrefServices.getStatus(created.task_uuid)), 1000)
    } else {
      dispatch(GrefServices.getAll({ eic: currentEic, page: 1 }))
    }
  }, [taskStatus])

  return (
    <div className="d-flex w-100 justify-content-between header">
      <div className="h-100 justify-content-between align-items-between">
        <h1 className="main-title">{t('importRejects.header.fileImport')}</h1>
        <h3 className="sub-title">{t('importRejects.header.importHistory')}</h3>
      </div>
      <UploadButton
        title={t('importRejects.header.importNewFile')}
        style={ButtonStyle.primary}
        onSelectedFile={onSelectedFile}
        startIcon={<FileUploadOutlinedIcon />}
        disabled={statusLoading}
      />
      <FeedbackSnackBar />
    </div>
  )
}
