import { DateTime } from 'luxon'
import { User } from 'services/UserServices/cerbereTypes'

const formatDate = (date: string): string => (
  DateTime.fromISO(date).setLocale('fr').toFormat("dd/MM/yyyy à HH'h'mm")
)

const formatLastUpdate = (date: string): string => {
  const date1 = DateTime.fromISO(date)
  const date2 = DateTime.fromISO(DateTime.now().toISO())
  const diff = date2.diff(date1, ['years', 'months', 'days', 'hours', 'minutes']).toObject()
  if (diff.days) {
    return `Il y a ${Math.floor(diff.days)} jours`
  }

  return `Il y a ${Math.floor(diff.hours as number)} heures`
}

const convertIdToName = (userList: User[], id: string): string => {
  const userInfo = userList.find(user => user.id === (id))
  if (userInfo) {
    return `${userInfo.firstName} ${userInfo.lastName}`
  }
  return 'Personne inconnue'
}

export { formatDate, formatLastUpdate, convertIdToName }
