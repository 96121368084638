import { SxProps } from '@mui/material'
import { getCSSValue } from 'helpers'

export enum Status {
  treat = 'À traiter',
  failed = 'Echec du traitement',
  pending = 'En attente du réaccord de la planche',
  export = 'En attente de l\'export CSV',
  done = 'Finalisé, pas d\'action nécessaire',
  oca = 'OCA à créer',
}

const treatStyle: SxProps = {
  backgroundColor: getCSSValue('--color-reject-treat'),
  borderRadius: '4px',
  fontSize: 16,
  fontFamily: 'Avenir',
  fontStyle: 'normal',
  fontWeight: 900,
  lineHeight: '120%',
  color: '#183B68',
}
const failedStyle: SxProps = {
  backgroundColor: getCSSValue('--color-reject-failed'),
  borderRadius: '4px',
  fontSize: 16,
  fontFamily: 'Avenir',
  fontStyle: 'normal',
  fontWeight: 900,
  lineHeight: '120%',
  color: 'white',
}

const pendingStyle: SxProps = {
  backgroundColor: getCSSValue('--color-reject-pending'),
  fontSize: 16,
  borderRadius: '4px',
  fontFamily: 'Avenir',
  fontStyle: 'normal',
  fontWeight: 900,
  lineHeight: '120%',
  color: '#183B68',
}
const exportStyle: SxProps = {
  backgroundColor: getCSSValue('--color-reject-export'),
  borderRadius: '4px',
  fontSize: 16,
  fontFamily: 'Avenir',
  fontStyle: 'normal',
  fontWeight: 900,
  lineHeight: '120%',
  color: '#183B68',
}
const doneStyle: SxProps = {
  backgroundColor: getCSSValue('--color-reject-done'),
  borderRadius: '4px',
  fontSize: 16,
  fontFamily: 'Avenir',
  fontStyle: 'normal',
  fontWeight: 900,
  lineHeight: '120%',
  color: 'white',
}

const ocaStyle: SxProps = {
  backgroundColor: getCSSValue('--color-reject-oca'),
  borderRadius: '4px',
  fontSize: 16,
  fontFamily: 'Avenir',
  fontStyle: 'normal',
  fontWeight: 900,
  lineHeight: '120%',
  color: 'white',
}

export {
  treatStyle, failedStyle, pendingStyle, exportStyle, doneStyle, ocaStyle,
}
