/* eslint-disable @typescript-eslint/no-explicit-any */
import { post } from '@osrdata/app_core/dist/requests'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { ThunkApiConfig } from 'types'
import { User } from './cerbereTypes'
import URI from '../uri'

const getUsersListInfo = createAsyncThunk<User[], string[], ThunkApiConfig>(
  'users/getUsersListInfo',
  async (users, thunkApi) => {
    try {
      const response: User[] = await post(`/${URI.cerbereApi}/users/simple/`, users)
      return response as User[]
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const UserServices = {
  getUsersListInfo,
}

export default UserServices
