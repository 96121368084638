/* eslint-disable camelcase */
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  ExportModif, ExportResponse, Gref, GridOrder, ImportTask, MouseEventAnalysis, RejectDetails, RejectResponse,
  SheetIndexes, SheetInfo, SheetReaccord, SheetResponse, TmpLocation,
} from 'objects/types'
import GrefServices, { FilterItem, GetSheetsParams } from 'services/GrefServices'
import UserServices from 'services/UserServices'
import { User } from 'services/UserServices/cerbereTypes'
import set from 'lodash/set'

export interface GrefState {
  // TODO: differents reducers
  next: string;
  count: string;
  previous: string;
  page_size: string;
  loading: boolean;
  grefsLoading: boolean;
  created: string;
  grefs: Gref[];
  userList: User[];
  dataView: boolean;
  exports: ExportResponse;
  newExport: ExportModif;
  csvContent: string;
  sheets: SheetInfo[];
  // TODO: put these in own reject reducer
  rejects: RejectResponse;
  filters: FilterItem[];
  filterMenuOpen: boolean;
  sort: GridOrder;
  currentReject: RejectDetails;
  currentRejectLoading: boolean;
  rejectsLoading: boolean;
  sheetInfo: SheetInfo;
  sheetCount: string;
  allRejects: RejectResponse;
  sheetsLoading: boolean;
  pageNumber: number;
  previewExportLoading: boolean;
  pinnedColumns: string[];
  mouseEventAnalysis: MouseEventAnalysis;
  tmpLocations: TmpLocation;
  currentIndex: SheetIndexes;
  reaccordList: SheetReaccord;
  reaccordLoading: boolean;
  taskStatus: ImportTask;
  statusLoading: boolean;
}

const initialReject: RejectDetails = {
  id: '',
  sheet: {
    id: '',
    serial: '',
    date: '',
    week: '',
    reaccord: false,
    previous_dates: [],
  },
  protection_id: '',
  protection_name: '',
  eic_zone: '',
  reject_type: '',
  error_code: '',
  sheet_rejected: false,
  status: '',
  previous_analysis: '',
  analysis_by: '',
  import_date: '',
  locations: [],
  free_comment: '',
  libelle_comment: '',
  current_analysis: '',
  error_reason: '',
  history: [],
}
const initialState: GrefState = {
  next: '',
  previous: '',
  count: '',
  page_size: '',
  loading: false,
  grefsLoading: true,
  created: '',
  grefs: [],
  userList: [],
  rejects: {
    results: [],
    count: '',
    page_size: '',
    next: '',
    previous: '',
  },
  currentReject: initialReject,
  dataView: false,
  exports: {
    results: [],
    count: '',
    page_size: '',
    next: '',
    previous: '',
  },
  newExport: {
    id: '',
    saved: false,
    rows: [],
  },
  csvContent: '',
  sheets: [],
  sheetCount: '',
  rejectsLoading: true,
  sheetInfo: {
    id: '',
    serial: '',
    date: '',
    week: '',
    reaccord: false,
    rejects: [],
    previous_dates: [],
  },
  allRejects: {
    results: [],
    count: '',
    page_size: '',
    next: '',
    previous: '',
  },
  sheetsLoading: false,
  currentRejectLoading: false,
  pageNumber: 1,
  previewExportLoading: false,
  filterMenuOpen: false,
  filters: [],
  pinnedColumns: [],
  sort: {
    field: '',
    sort: undefined,
  },
  mouseEventAnalysis: {
    reject: initialReject,
    rejectsToChange: [],
    mouseUp: true,
    mouseDown: false,
    y: 0,
    direction: null,
  },
  tmpLocations: {},
  currentIndex: { sheet: 0, reject: 0 },
  reaccordList: {
    rows: [],
  },
  reaccordLoading: true,
  taskStatus: { status: '', task_uuid: '' },
  statusLoading: false,
}

export interface UpdateField {
  path: Array<string> | string;
  value: string;
}

const getObjects = (state: GrefState, action: PayloadAction<SheetResponse, string, { arg: GetSheetsParams }>) => {
  if (action.meta.arg.appendSheets) {
    if (state.sheets.length && action.payload.results.length) {
      if (state.sheets.find(elem => elem.id === action.payload.results[0].id) !== undefined) {
        return
      }
    }
    if (action.payload.results) {
      state.sheets.push(...action.payload.results)
    }
  } else {
    state.sheets = action.payload.results
  }
}
export const grefsSlices = createSlice({
  name: 'grefs',
  initialState,
  reducers: {
    toggleSendFile: state => {
      state.statusLoading = !state.statusLoading
    },
    toggleDataView: state => {
      state.dataView = !state.dataView
    },
    setCurrentRejectField: (state, action: PayloadAction<UpdateField>) => {
      const { value, path } = action.payload
      state.currentReject = set(state.currentReject, path, value)
    },
    addCurrentRejectLocation: state => {
      const newLocation = {
        line_code: '',
        track_name: '',
        kp_start: '',
        kp_end: '',
      }
      state.currentReject.locations.push(newLocation)
    },
    removeCurrentRejectLocation: (state, action: PayloadAction<number>) => {
      state.currentReject.locations = state.currentReject.locations.filter((_value, i) => i !== action.payload)
    },
    resetCsv: state => {
      state.csvContent = ''
      state.newExport.id = ''
    },
    resetSheets: state => {
      state.sheets = []
      state.currentIndex = { sheet: 0, reject: 0 }
      state.pageNumber = 1
    },
    resetCurrentReject: state => {
      state.currentReject = initialReject
    },
    addFilter: (state, action: PayloadAction<FilterItem>) => {
      state.filters.push(action.payload)
    },
    setFilter: (state, action: PayloadAction<FilterItem>) => {
      const newState = state.filters.map(obj => (
        obj.id === action.payload.id ? { ...action.payload } : obj
      ))
      state.filters = newState
    },
    removeFilter: (state, action: PayloadAction<FilterItem>) => {
      state.filters = state.filters?.filter(filter => filter.id !== action.payload.id)
    },
    setSort: (state, action: PayloadAction<GridOrder>) => {
      state.sort = action.payload
    },
    setIndex: (state, action: PayloadAction<SheetIndexes>) => {
      state.currentIndex = { sheet: action.payload.sheet, reject: action.payload.reject }
    },
    setPageNumber: (state, action: PayloadAction<number>) => {
      state.pageNumber = action.payload
    },
    setFilterMenu: (state, action: PayloadAction<boolean>) => {
      state.filterMenuOpen = action.payload
    },
    setPinnedColumn: (state, action: PayloadAction<string>) => {
      state.pinnedColumns.push(action.payload)
    },
    removePinnedColumn: (state, action: PayloadAction<string>) => {
      state.pinnedColumns = state.pinnedColumns.filter(item => item !== action.payload)
    },
    setMouseDown: (state, action: PayloadAction<MouseEventAnalysis>) => {
      state.mouseEventAnalysis = action.payload
    },
    resetMouseEvent: state => {
      state.mouseEventAnalysis = {
        reject: initialReject,
        rejectsToChange: [],
        mouseUp: true,
        mouseDown: false,
        y: 0,
        direction: null,
      }
    },
    setTmpLocations: (state, action: PayloadAction<TmpLocation>) => {
      state.tmpLocations = action.payload
    },
  },
  extraReducers: builder => {
    builder.addCase(GrefServices.getAll.fulfilled, (state, action) => {
      Object.assign(state, action.payload)
      state.grefsLoading = false
    })
    builder.addCase(GrefServices.getAll.pending, state => {
      state.grefsLoading = true
    })
    builder.addCase(GrefServices.addGref.fulfilled, (state, action) => {
      state.created = action.payload
      state.loading = false
    })
    builder.addCase(GrefServices.addGref.rejected, state => {
      state.loading = false
    })
    builder.addCase(GrefServices.getRejects.fulfilled, (
      state, action,
    ) => {
      state.rejectsLoading = false
      Object.assign(state.rejects, action.payload)
    })
    builder.addCase(GrefServices.getAllRejects.fulfilled, (state, action) => {
      state.allRejects = action.payload
    })
    builder.addCase(GrefServices.getSheets.pending, state => {
      state.sheetsLoading = true
    })
    builder.addCase(GrefServices.getSheets.fulfilled, (state, action) => {
      state.sheetsLoading = false
      state.sheetCount = action.payload.count
      getObjects(state, action)
    })
    builder.addCase(GrefServices.getRejects.pending, state => {
      state.rejectsLoading = true
    })
    builder.addCase(GrefServices.getRejects.rejected, state => {
      state.rejectsLoading = false
    })
    builder.addCase(UserServices.getUsersListInfo.fulfilled, (state, action) => {
      const userIds = state.userList.map(u => u.id)
      action.payload.forEach(user => {
        if (!userIds.includes(user.id)) {
          state.userList.push(user)
        }
      })
    })
    builder.addCase(GrefServices.getReject.pending, state => {
      state.currentRejectLoading = true
    })
    builder.addCase(GrefServices.getReject.fulfilled, (state, action) => {
      state.currentRejectLoading = false
      state.currentReject = action.payload
      state.rejects.results = state.rejects.results.map(reject => {
        if (reject.sheet.id !== action.meta.arg.id) return reject
        return {
          ...reject,
          ...action.payload,
        }
      })
    })
    builder.addCase(GrefServices.updateReject.fulfilled, (state, action) => {
      state.rejects.results = state.rejects.results.map(reject => {
        if (reject.id !== action.meta.arg.id) return reject
        return action.payload
      })
      if (state.currentReject.id === action.payload.id) {
        state.currentReject = action.payload
      }
    })
    builder.addCase(GrefServices.updateSheet.fulfilled, (state, action) => {
      state.rejects.results = state.rejects.results.map(reject => {
        if (reject.sheet.id !== action.meta.arg.id) return reject
        return {
          ...reject,
          sheet: {
            ...reject.sheet,
            ...action.payload,
          },
        }
      })
      if (state.currentReject.id === action.meta.arg.rejectId) {
        state.currentReject.sheet = {
          ...state.currentReject.sheet,
          ...action.payload,
        }
      }
    })
    builder.addCase(GrefServices.getExports.fulfilled, (state, action) => {
      Object.assign(state.exports, action.payload)
      state.grefsLoading = false
    })
    builder.addCase(GrefServices.getExports.pending, state => {
      state.grefsLoading = true
    })
    builder.addCase(GrefServices.sendExport.pending, state => {
      state.previewExportLoading = true
    })
    builder.addCase(GrefServices.sendExport.fulfilled, (state, action) => {
      state.previewExportLoading = false
      state.newExport = action.payload
    })
    builder.addCase(GrefServices.getCsv.fulfilled, (state, action) => {
      state.csvContent = action.payload
    })
    builder.addCase(GrefServices.getExportDetails.pending, state => {
      state.reaccordLoading = true
    })
    builder.addCase(GrefServices.getExportDetails.fulfilled, (state, action) => {
      state.reaccordList = action.payload
      state.reaccordLoading = false
    })
    builder.addCase(GrefServices.getStatus.fulfilled, (state, action) => {
      if (action.payload.status !== 'PENDING') {
        state.statusLoading = false
      } else {
        state.statusLoading = true
      }
      state.taskStatus = action.payload
    })
  },
})

export const {
  toggleSendFile,
  toggleDataView,
  setCurrentRejectField,
  addCurrentRejectLocation,
  removeCurrentRejectLocation,
  resetCsv,
  resetSheets,
  resetCurrentReject,
  setFilter,
  setSort,
  setPageNumber,
  setIndex,
  setFilterMenu,
  removeFilter,
  addFilter,
  setPinnedColumn,
  removePinnedColumn,
  setMouseDown,
  resetMouseEvent,
  setTmpLocations,
} = grefsSlices.actions

export default grefsSlices.reducer
