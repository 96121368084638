/* eslint-disable @typescript-eslint/no-explicit-any */
import { get } from '@osrdata/app_core/dist/requests'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { Eic } from 'objects/types'
import { ThunkApiConfig } from 'types'
import URI from '../uri'

const getAll = createAsyncThunk<Eic[], string | undefined, ThunkApiConfig>(
  'eic/getAll',
  async (search, thunkApi) => {
    try {
      const response = await get(`/apo/${URI.eic}/`, {
        search: search || '',
      })
      return response
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const EicServices = {
  getAll,
}

export default EicServices
