/* eslint-disable arrow-body-style */
import { DateTime } from 'luxon'

const formatDateExport = (date: DateTime | null): string => {
  if (date === null) {
    return DateTime.now().toFormat('yyyy-MM-dd')
  }
  return DateTime.fromISO(date.toString()).toFormat('yyyy-MM-dd')
}

const currentDate = (): DateTime => {
  return DateTime.now()
}

const currentDateToMinutes = (): string => {
  return DateTime.now().toFormat("yyyy-MM-dd HH'h'mm")
}

export { formatDateExport, currentDate, currentDateToMinutes }
