import { useTranslation } from '@osrdata/app_core/dist/translation'
import {
  ReactElement, SyntheticEvent, useEffect, useState,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'

import Snackbar, { SnackbarCloseReason } from '@mui/material/Snackbar'
import Alert from '@mui/material/Alert'

import {
  FeedbackState, setError, setMessage, setMessageReady,
} from 'reducers/feedback'
import { RootState, store } from 'Store'
import { DEFAULT_AUTOCLOSE_DURATION, handleFeedback, Severity } from './utils'
import './FeedbackSnackBar.scss'

export default function FeedbackSnackBar(): ReactElement {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { feedback, message, messageReady } = useSelector((state: RootState) => state.feedback as FeedbackState)
  const [open, setOpen] = useState(false)
  const [severity, setSeverity] = useState<Severity>(Severity.error)
  const [autoCloseDuration, setAutoCloseDuration] = useState<number | null>(DEFAULT_AUTOCLOSE_DURATION)

  useEffect(() => {
    if (feedback) {
      handleFeedback(feedback, setOpen, setSeverity, setAutoCloseDuration)
    }
  }, [feedback])

  const onClose = (_evt: SyntheticEvent | Event, reason: SnackbarCloseReason) => {
    if (reason === 'clickaway' && (feedback?.code === 201 || feedback?.code === 0)) { return }
    setOpen(false)
    dispatch(setMessage(''))
    dispatch(setMessageReady(false))
    setAutoCloseDuration(DEFAULT_AUTOCLOSE_DURATION)
    store.dispatch(setError())
  }

  return (
    <Snackbar
      id="feedback-snackbar"
      open={open}
      onClose={onClose}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      autoHideDuration={autoCloseDuration}
    >
      <Alert severity={severity} variant="filled">
        {messageReady ? message : t(message)}
      </Alert>
    </Snackbar>
  )
}
