import {
  useEffect, Suspense, ReactElement, useState,
} from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  Router, Switch, Route, Redirect,
} from 'react-router-dom'
import AdapterLuxon from '@mui/lab/AdapterLuxon'
import Loader from 'components/Common/Loader'
import { auth } from '@osrdata/app_core'
import history from 'customHistory'
import 'App.scss'
import { RootState } from 'Store'
import Home from 'views/Home'
import TopBar from 'components/TopBar/TopBar'
import AccessDenied from 'AccessDenied'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'

const APP_NAME = 'ANAGREF'
const ACCESS_PERM = 'apo::ACCESS'

export default function App(): ReactElement {
  const dispatch = useDispatch()
  const { isLogged, appPermissions } = useSelector((state: RootState) => state.user)
  const [isLoading, setIsLoading] = useState(true)
  const [accessDenied, setAccessDenied] = useState(false)

  useEffect(() => {
    dispatch(auth.attemptLoginOnLaunch())
  }, [])

  useEffect(() => {
    if (isLogged && appPermissions.length > 0) {
      if (!appPermissions.includes(ACCESS_PERM)) {
        setAccessDenied(true)
      }
      setIsLoading(false)
    }
  }, [isLogged, appPermissions])

  return (
    <>
      <LocalizationProvider dateAdapter={AdapterLuxon} locale="fr">
        <Suspense fallback={<div className="d-flex app-container"><Loader /></div>}>
          <TopBar appName={APP_NAME} />
          {accessDenied ? <AccessDenied /> : (
            <div className="d-flex app-container">
              {isLoading && <Loader message="Chargement" />}
              {!isLoading && (
                <Router history={history}>
                  <Switch>
                    <Route path="/imports">
                      <Home tab={0} />
                    </Route>
                    <Route path="/rejects">
                      <Home tab={1} />
                    </Route>
                    <Route path="/exports">
                      <Home tab={2} />
                    </Route>
                    <Redirect from="/" to="/imports" />
                  </Switch>
                </Router>
              )}
            </div>
          )}
        </Suspense>
      </LocalizationProvider>
    </>
  )
}
