function filterFields<T>(
  partial: Partial<T>, fields: Array<keyof T>, excludedFields: Array<keyof T> = [],
): Partial<T> {
  return (Object.keys(partial) as Array<keyof T>)
    .filter(key => fields.includes(key) && !excludedFields.includes(key))
    .reduce((obj, key) => {
      const value = partial[key]
      if (value !== undefined) {
        return {
          ...obj,
          [key]: value,
        }
      }
      return obj
    }, {})
}

export default filterFields
