/* eslint-disable camelcase */
import {
  Pagination, Paper, Table, TableBody, TableCell, TableContainer, TableRow,
} from '@mui/material'
import { useTranslation } from '@osrdata/app_core/dist/translation'
import FeedbackSnackBar from 'components/Common/FeedbackSnackBar/FeedbackSnackBar'
import InfoPanel, { Severity } from 'components/Common/InfoPanel/InfoPanel'
import Loader from 'components/Common/Loader'
import FilterSelect, { FilterMode } from 'components/RejectDataView/FilterSelect'
import { Reject } from 'objects/types'
import {
  ChangeEvent, ReactElement, useEffect, useRef, useState,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { EicState } from 'reducers/eics'
import { GrefState } from 'reducers/grefs'
import GrefServices from 'services/GrefServices'
import { User } from 'services/UserServices/cerbereTypes'
import { RootState } from 'Store'
import { Column, columnsHeader } from './const'
import './CustomTable.scss'
import CustomTableHead from './CustomTableHead'

type Props = {
  rows: Reject[];
  sheet: number | null;
  onRowClick: (e: Reject) => void;
}

export default function CustomTable({ rows, sheet, onRowClick }: Props): ReactElement {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const tableRef = useRef<HTMLTableElement>(null)
  const {
    rejects, filters, sort, filterMenuOpen, pinnedColumns, mouseEventAnalysis, rejectsLoading,
  } = useSelector((state: RootState): GrefState => state.grefs)
  const { count, page_size: pageSize } = rejects
  const { currentEic } = useSelector((state: RootState) => state.eics) as EicState
  const userList = useSelector((state: RootState) => state.grefs.userList) as User[]
  const [page, setPage] = useState(1)
  const pagesNumber = Math.ceil(Number(count) / Number(pageSize)) || 1
  const [pinnedCols, setPinned] = useState<Column[]>([])
  const [notPinnedCols, setCols] = useState<Column[]>([])

  useEffect(() => {
    if (pinnedColumns.length === 0) {
      setCols(columnsHeader)
      setPinned([])
      return
    }
    const colsPin: Column[] = []
    const colsNotPin = columnsHeader.filter(elem => !pinnedColumns.includes(elem.field))
    pinnedColumns.forEach(pin => {
      const col = columnsHeader.find(elem => elem.field === pin)
      if (col !== undefined) {
        colsPin.push(col)
      }
    })
    setPinned(colsPin)
    setCols(colsNotPin)
  }, [pinnedColumns])

  useEffect(() => {
    setPage(1)
  }, [currentEic, filters, sort])

  const handleChangePage = (_event: ChangeEvent<unknown> | null, newPage: number): void => {
    if (tableRef.current) {
      tableRef.current.scrollTo(0, 0)
    }
    setPage(newPage)
    dispatch(GrefServices.getRejects({
      eic: currentEic, page: newPage, filterItems: filters, sortItem: sort,
    }))
  }
  const analysisInSelection = (reject: Reject, property: string): boolean => {
    if (property !== 'current_analysis') {
      return false
    }
    const result = mouseEventAnalysis.rejectsToChange.find(elem => elem.id === reject.id)
    return result !== undefined || mouseEventAnalysis.reject.id === reject.id
  }

  const renderRows = (reject: Reject) => {
    const wid: string[] = []
    pinnedCols.forEach((_col, index) => {
      if (index === 0) {
        wid.push('0')
        return
      }
      const val = Number(Number(wid[index - 1].slice(0, -2)) + Number(pinnedCols[index - 1].width.slice(0, -2)))
      wid.push(String(val).concat('px'))
    })
    return (
      <TableRow
        hover
        key={reject.id}
        onClick={() => onRowClick(reject)}
        className={`reject-row ${sheet === Number(reject.id) ? 'active-row' : ''}`}
      >
        {pinnedCols.map((column, index) => (
          <TableCell
            key={`${reject.id}-${column.title}-pinned`}
            align={column.align}
            className={`pinned-cell ${analysisInSelection(reject, column.propertyName) ? 'analysis-select' : ''} `}
            sx={{
              minWidth: column.width,
              maxWidth: column.width,
              left: wid[index],
              position: 'sticky',
            }}
          >
            {column.formatter(reject[column.propertyName], userList, reject)}
          </TableCell>
        ))}
        {notPinnedCols.map(column => (
          <TableCell
            key={`${reject.id}-${column.title}`}
            align={column.align}
            sx={{
              minWidth: column.width,
              maxWidth: column.width,
              position: column.field === 'current_analysis' ? 'relative' : 'static',
            }}
            className={`${analysisInSelection(reject, column.propertyName) ? 'analysis-select' : ''} `}
          >
            {column.formatter(reject[column.propertyName], userList, reject)}
          </TableCell>
        ))}
      </TableRow>
    )
  }

  return (
    <Paper className="reject-main-container" elevation={0} square>
      {filterMenuOpen ? (
        <div style={{ position: 'relative', zIndex: 2000 }}>
          <FilterSelect mode={FilterMode.deleteItems} />
        </div>
      ) : null}

      {rejectsLoading ? (
        <div
          style={{ minHeight: '65vh', maxHeight: '65vh' }}
          className=" d-flex justify-content-center align-items-center"
        >
          <Loader />
        </div>
      ) : (
        <TableContainer
          ref={tableRef}
          className="reject-table-container"
          sx={{ width: '100%', height: 'calc(100vh - 300px)' }}
        >
          <Table stickyHeader className="reject-table-content" aria-label="simple table">
            <CustomTableHead
              pinnedCols={pinnedCols}
              notPinnedCols={notPinnedCols}
            />
            {rejects.results.length !== 0 && (
              <TableBody>
                {
                  rows.map((reject: Reject) => (
                    renderRows(reject)
                  ))
                }
              </TableBody>
            )}
          </Table>
        </TableContainer>
      )}
      {rejects.results.length === 0 && (
        <InfoPanel
          title={t('TreatRejects.noReject.title')}
          severity={Severity.warning}
        />
      )}
      <div className="d-flex footer align-items-end justify-content-center">
        <Pagination
          onChange={handleChangePage}
          variant="outlined"
          page={page}
          shape="rounded"
          boundaryCount={1}
          siblingCount={1}
          count={pagesNumber}
        />
      </div>
      <FeedbackSnackBar />
    </Paper>
  )
}
