/* eslint-disable @typescript-eslint/no-explicit-any */
import { Paper } from '@mui/material'
import CustomTable from 'components/CustomTable/CustomTable'
import RejectDataView from 'components/RejectDataView/RejectDataView'
import RejectHeader from 'components/RejectHeader/RejectHeader'
import { Reject, TmpLocation } from 'objects/types'
import {
  ReactElement, useEffect, useRef, useState,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { EicState } from 'reducers/eics'
import { GrefState, resetMouseEvent, setTmpLocations } from 'reducers/grefs'
import GrefServices from 'services/GrefServices'
import UserServices from 'services/UserServices'
import { RootState } from 'Store'
import './RejectHome.scss'

export default function RejectHome(): ReactElement {
  const dispatch = useDispatch()
  const {
    rejects, dataView, filters, sort, mouseEventAnalysis, rejectsLoading,
  } = useSelector((state: RootState): GrefState => state.grefs)
  const { currentEic } = useSelector((state: RootState) => state.eics) as EicState
  const [selectedSheet, setSheet] = useState<number | null>(null)
  const [rows, setRows] = useState(rejects.results)
  const init = useRef<boolean>(false)

  useEffect(() => {
    setRows(rejects.results)
    const locs: TmpLocation = { 0: {} }
    rejects.results.forEach(elem => {
      locs[elem.id] = { ...elem.locations[0] }
    })
    const usersId: string[] = []
    rejects.results.forEach((rej: Reject) => {
      if (usersId.indexOf(rej.analysis_by) === -1) {
        usersId.push(rej.analysis_by)
      }
    })
    dispatch(UserServices.getUsersListInfo(usersId))
    dispatch(setTmpLocations(locs))
  }, [rejects])

  const handleRowSelection = (reject: Reject) => {
    setSheet(Number(reject.id))
  }
  const isEmpty = (s: string | null | undefined) => s == null || s.length === 0

  const updateRejects = () => {
    dispatch(GrefServices.getRejects({ eic: currentEic, filterItems: filters, sortItem: sort }))
  }

  useEffect(() => {
    if (!init.current) {
      init.current = true
    } else {
      updateRejects()
    }
  }, [currentEic, sort])

  useEffect(() => {
    if (init.current && filters.length >= 0) {
      if (filters.length === 0) {
        updateRejects()
        return
      }
      const lastFilterValue: string | null | undefined = filters[filters.length - 1]?.value
      if (!isEmpty(lastFilterValue)) {
        updateRejects()
      }
    }
  }, [filters])

  const renderRejects = () => {
    if (rejects.results.length > 0 || (rejects.results.length === 0 && filters.length) || rejectsLoading) {
      return dataView ? (
        <div style={{ flexGrow: 1, alignItems: 'space-between' }}>
          <RejectDataView rejectId={selectedSheet} />
        </div>
      ) : (
        <CustomTable rows={rows} sheet={selectedSheet} onRowClick={handleRowSelection} />
      )
    }
    return ''
  }

  const handleMouseUp = () => {
    if (mouseEventAnalysis.mouseDown) {
      dispatch(resetMouseEvent())
    }
  }

  return (
    <Paper onMouseUp={handleMouseUp} square elevation={0}>
      <RejectHeader />
      {renderRejects()}
    </Paper>
  )
}
