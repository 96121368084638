import { ReactElement } from 'react'
import TabContext from '@mui/lab/TabContext'
import Tab from '@mui/material/Tab'
import TabPanel from '@mui/lab/TabPanel'
import TabList from '@mui/lab/TabList'
import './Home.scss'
import { useTranslation } from '@osrdata/app_core/dist/translation'
import ImportHome from 'components/ImportHome/ImportHome'
import RejectHome from 'components/RejectHome/RejectHome'
import ExportHome from 'components/ExportHome/ExportHome'
import history from 'customHistory'

interface TabType {
  key: string;
  value: string;
  label: string;
  component?: ReactElement;
}

const HOME_TABS: TabType[] = [
  {
    key: 'imports',
    value: '0',
    label: 'NavBar.tab.importRejects',
    component: <ImportHome />,
  },
  {
    key: 'rejects',
    value: '1',
    label: 'NavBar.tab.treatRejects',
    component: <RejectHome />,
  },
  {
    key: 'exports',
    value: '2',
    label: 'NavBar.tab.exportForTable',
    component: <ExportHome />,
  },
]

type Props = {
  tab: number;
}

export default function Home({ tab }: Props): ReactElement {
  const { t } = useTranslation()
  const handleTabChange = (tabKey: string) => {
    history.push(tabKey)
  }

  return (
    <div className="w-100" id="home">
      <TabContext value={String(tab)}>
        <TabList aria-label="tabs menu">
          {HOME_TABS.map(homeTab => (
            <Tab
              key={homeTab.key}
              label={t(homeTab.label)}
              value={homeTab.value}
              className="tabs-menu-item"
              onClick={() => handleTabChange(homeTab.key)}
            />
          ))}
        </TabList>
        {HOME_TABS.map(homeTab => (
          <TabPanel
            key={homeTab.key}
            value={homeTab.value}
          >
            {homeTab.component}
          </TabPanel>
        ))}
      </TabContext>
    </div>
  )
}
