import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import GrefServices from 'services/GrefServices'
import { ResponseError } from 'types'
import allErrorsMatcher from './matchers/matchers'

export enum FeedbackType {
  validation = 'validation'
}

export interface FeedbackState {
  feedback?: ResponseError;
  message: string;
  messageReady?: boolean;
}

const initialState: FeedbackState = {
  feedback: undefined,
  message: '',
  messageReady: false,
}

export const feedbackSlice = createSlice({
  name: 'feedback',
  initialState,
  reducers: {
    setError: (state, action: PayloadAction<ResponseError | undefined>) => {
      state.feedback = action.payload
    },
    setMessage: (state, action: PayloadAction<string | undefined>) => {
      state.message = action.payload || ''
    },
    setMessageReady: (state, action: PayloadAction<boolean>) => {
      state.messageReady = action.payload
    },
  },
  extraReducers: builder => {
    // Update handling
    builder.addCase(GrefServices.updateReject.fulfilled, state => {
      state.feedback = { code: 200, data: { message: 'TreatRejects.feedback.rejectUpdated' } }
    })
    builder.addCase(GrefServices.updateSheet.fulfilled, state => {
      state.feedback = { code: 200, data: { message: 'TreatRejects.feedback.sheetUpdated' } }
    })
    builder.addCase(GrefServices.addGref.pending, state => {
      state.feedback = { code: 202, data: { message: 'importRejects.header.pendingImport' } }
    })
    builder.addCase(GrefServices.addGref.fulfilled, state => {
      state.feedback = { code: 202, data: { message: 'importRejects.Success.importFile' } }
    })
    builder.addCase(GrefServices.getStatus.fulfilled, (state, action) => {
      if (action.payload.status !== 'PENDING') {
        state.feedback = { code: 201, data: { message: 'importRejects.Success.importDone' } }
      }
    })
    builder.addCase(GrefServices.updateReject.rejected, (state, action) => {
      if (action.payload?.data?.detail) {
        state.feedback = { code: 400, data: { message: action.payload.data.detail } }
        state.messageReady = true
      } else {
        state.feedback = { code: 400, data: { message: 'Error.badRequest' } }
      }
    })
    builder.addCase(GrefServices.getRejects.rejected, (state, action) => {
      if (action.payload?.data?.detail) {
        state.feedback = { code: 400, data: { message: action.payload.data.detail } }
        state.messageReady = true
      }
    })
    // Error Handling
    builder.addMatcher(allErrorsMatcher, (state, action) => {
      state.feedback = action.payload
    })
  },
})

export const { setError, setMessage, setMessageReady } = feedbackSlice.actions

export default feedbackSlice.reducer
