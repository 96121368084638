/* eslint-disable arrow-body-style */
import { getGridBooleanOperators, getGridStringOperators, GridFilterOperator } from '@mui/x-data-grid'
import CellDatesContainer from 'components/Common/CellDatesContainer/CellDatesContainer'
import CustomDraggableCell from 'components/Common/CustomDraggableCell/CustomDraggableCell'
import CustomStatusBadge from 'components/Common/CustomStatusBadge/CustomStatusBadge'
import CustomCheckbox from 'components/Common/Inputs/CustomCheckbox/CustomCheckbox'
import CustomSelect from 'components/Common/Inputs/CustomSelect/CustomSelect'
import CustomTextField from 'components/Common/Inputs/CustomTextField/CustomTextField'
import { convertIdToName } from 'components/ImportHome/utils'
import { formatSimpleDatetime } from 'components/RejectHome/utils'
import {
  Location, Reject, RejectDetails, Sheet,
} from 'objects/types'
import { ReactElement } from 'react'
import { setError } from 'reducers/feedback'
import { setTmpLocations } from 'reducers/grefs'
import GrefServices, { GridOperator } from 'services/GrefServices'
import { User } from 'services/UserServices/cerbereTypes'
import filterFields from 'services/utils'
import { store } from 'Store'

export const EMPTY_LOCATION: Location = {
  id: undefined,
  line_code: undefined,
  track_name: undefined,
  kp_end: undefined,
  kp_start: undefined,
}

// const LOCATION_FIELDS: Array<keyof Location> = ['line_code', 'track_name', 'kp_end', 'kp_start']

const ANALYSIS_LIST = [
  'Impact, traité par Table de correspondance',
  'Pas d\'impact, traité par Table de correspondance', 'Impact, traité OCA', 'Pas d\'impact, non traité',
  'Planche annulée', 'Jamais analysé', 'Déjà traité par TDC', 'En cours d\'étude',
]

export const REJECT_TYPE = [
  'Ano Gaïa', 'Géo non loc.',
  'DP Périmé',
]

export const MANDATORY_FIELDS: Array<keyof RejectDetails> = ['id', 'eic_zone', 'locations']

const isLocationFilled = (id: string) => {
  const { tmpLocations } = store.getState().grefs
  const location = tmpLocations[id]
  let flag = true
  if (Object.keys(location).length !== 0) {
    if (+location.kp_start >= +location.kp_end) {
      return false
    }
    Object.keys(location).forEach(p => {
      const prop = p as keyof Location
      if (prop !== 'id') {
        if (['', null, undefined].includes(location[prop])) {
          flag = false
        }
      }
    })
  } else {
    flag = false
  }
  return flag
}

export const checkLocationErrors = (rejectId: string, field: string): boolean => {
  const { tmpLocations } = store.getState().grefs
  const location = tmpLocations[rejectId]

  if (location === null || location === undefined) {
    return false
  }
  if (Object.keys(location).length === 0) {
    return false
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { id, ...newLoc } = location

  if (field === 'kp_start' || field === 'kp_end') {
    if (+newLoc.kp_start >= +newLoc.kp_end) {
      return true
    }
  }

  if ((newLoc[field as keyof Location] as string === null || newLoc[field as keyof Location] as string === ''
    || newLoc[field as keyof Location] as string === undefined)
    && Object.keys(newLoc).some(elem => newLoc[elem] !== null
      && newLoc[elem] !== undefined && newLoc[elem] !== '')) {
    return true
  }

  return false
}

const updateLocation = (field: string, value: string, rejectId: string) => {
  const { tmpLocations } = store.getState().grefs
  const tmp = { ...tmpLocations }

  if (Object.keys(tmp[rejectId]).length === 0) {
    tmp[rejectId] = { ...EMPTY_LOCATION }
  }

  if (field === 'kp_start') {
    if (+value >= +tmp[rejectId].kp_end) {
      store.dispatch(setError({ code: 0, data: { message: 'TreatRejects.feedback.kpError' } }))
    }
  }
  if (field === 'kp_end') {
    if (+tmp[rejectId].kp_start >= +value) {
      store.dispatch(setError({ code: 0, data: { message: 'TreatRejects.feedback.kpError' } }))
    }
  }
  tmp[rejectId] = { ...tmp[rejectId], [field]: value }
  store.dispatch(setTmpLocations(tmp))
}

export function updateValue<T>(keyPath: string | string[], updatedValue: T, row: RejectDetails): void {
  // Get mandatory row fields
  let reducedRow: Partial<RejectDetails> = filterFields(row, MANDATORY_FIELDS)
  // Add the updated field to row
  if (Array.isArray(keyPath)) {
    if (keyPath[0] === 'locations') {
      updateLocation(keyPath[1], String(updatedValue), row.id)
    }

    if (isLocationFilled(row.id)) {
      const { tmpLocations } = store.getState().grefs
      const location = tmpLocations[row.id]
      const otherLocations = reducedRow.locations && reducedRow.locations?.length >= 0
        ? reducedRow.locations?.filter(loc => loc.id !== location.id)
        : []
      reducedRow = {
        ...reducedRow,
        locations: [location, ...otherLocations],
      }
    } else {
      return
    }
  } else {
    reducedRow = {
      ...reducedRow,
      [keyPath]: updatedValue,
    }
  }
  // Dispatch the update action
  store.dispatch(GrefServices.updateReject(reducedRow))
}

export const updateSheet = async (row: RejectDetails, newValue: boolean): Promise<void> => {
  await store.dispatch(GrefServices.updateSheet({
    eic_zone: row.eic_zone,
    id: row.sheet.id,
    date: row.sheet.date,
    reaccord: newValue,
    rejectId: row.id,
  }))
  const { filters, sort } = store.getState().grefs
  const { currentEic } = store.getState().eics

  store.dispatch(GrefServices.getRejects({ eic: currentEic, filterItems: filters, sortItem: sort }))
}

export const getLocationValue = (rejectId: string, locationField: keyof Location): string | number | undefined => {
  const { tmpLocations } = store.getState().grefs
  const tmp = { ...tmpLocations }
  const x = tmp[rejectId]
  if (tmp[rejectId] === null || tmp[rejectId] === undefined) {
    return undefined
  }
  if (Object.keys(tmp[rejectId]).length === 0) {
    return undefined
  }
  return x[locationField]
}

export const hasMultipleLocations = (row: RejectDetails): boolean => row.locations && row.locations.length > 1

const USED_STRING_OPERATORS = [
  GridOperator.contains, GridOperator.equals, GridOperator.endsWith, GridOperator.startsWith,
]

export const FILTERED_STRING_OPERATORS = getGridStringOperators().filter(
  op => USED_STRING_OPERATORS.includes(op.value as GridOperator),
)

export type Formatter<T, P, R, S> = (t: T, p: R, s: S) => P

export type Column = {
  [index: number]: Column;
  title: string;
  propertyName: keyof Reject;
  field: string;
  width: string;
  align: 'inherit' | 'left' | 'center' | 'right' | 'justify';
  formatter: Formatter<unknown, | string | ReactElement | string, User[], Reject>;
  filterOperators: GridFilterOperator[];
}

const columnsHeader: Column[] = [
  {
    propertyName: 'sheet',
    field: 'serial',
    align: 'left',
    title: 'serial',
    width: '160px',
    formatter: (o): string => (o as Sheet).serial as string,
    filterOperators: FILTERED_STRING_OPERATORS,
  },
  {
    propertyName: 'sheet',
    title: 'date',
    field: 'date',
    align: 'left',
    width: '200px',
    formatter: (o: unknown): ReactElement => {
      return (
        <CellDatesContainer sheet={(o as Sheet)} />
      )
    },
    filterOperators: FILTERED_STRING_OPERATORS,
  },
  {
    propertyName: 'sheet',
    title: 'week',
    field: 'week',
    align: 'left',
    width: '150px',
    formatter: (o: unknown): string => (o as Sheet).week as string,
    filterOperators: FILTERED_STRING_OPERATORS,
  },
  {
    propertyName: 'protection_id',
    title: 'protectionId',
    field: 'protection_id',
    align: 'left',
    width: '300px',
    formatter: (o): string => o as string,
    filterOperators: FILTERED_STRING_OPERATORS,
  },
  {
    propertyName: 'eic_zone',
    title: 'eicZone',
    field: 'eic_zone',
    align: 'left',
    width: '100px',
    formatter: (o): string => o as string,
    filterOperators: FILTERED_STRING_OPERATORS,
  },
  {
    propertyName: 'locations',
    title: 'lineCode',
    field: 'line_code',
    align: 'left',
    width: '150px',
    filterOperators: FILTERED_STRING_OPERATORS,
    formatter: (_o: unknown, _users: unknown, reject: Reject): ReactElement => {
      return (
        <CustomTextField
          maxLength={10}
          error={checkLocationErrors(reject.id, 'line_code')}
          value={getLocationValue(reject.id, 'line_code') as string}
          onBlur={newValue => {
            if (String(newValue) !== String(getLocationValue(reject.id, 'line_code'))) {
              updateValue(['locations', 'line_code'], newValue as string, reject as RejectDetails)
            }
          }}
          endAdornment={hasMultipleLocations(reject as RejectDetails) ? <div className="adornment">*</div> : <span />}
        />
      )
    },
  },
  {
    propertyName: 'locations',
    title: 'trackName',
    field: 'track_name',
    align: 'left',
    width: '150px',
    filterOperators: FILTERED_STRING_OPERATORS,
    formatter: (_o: unknown, _users: unknown, reject: Reject): ReactElement => {
      return (
        <CustomTextField
          maxLength={10}
          error={checkLocationErrors(reject.id, 'track_name')}
          value={getLocationValue(reject.id, 'track_name') as string}
          onBlur={newValue => {
            if (String(newValue) !== String(getLocationValue(reject.id, 'track_name'))) {
              updateValue(['locations', 'track_name'], newValue as string, reject as RejectDetails)
            }
          }}
          endAdornment={hasMultipleLocations(reject as RejectDetails) ? <div className="adornment">*</div> : <span />}
        />
      )
    },
  },
  {
    propertyName: 'locations',
    title: 'kpStart',
    field: 'kp_start',
    align: 'left',
    width: '150px',
    filterOperators: FILTERED_STRING_OPERATORS,
    formatter: (_o: unknown, _users: unknown, reject: Reject): ReactElement => {
      return (
        <CustomTextField
          type="number"
          error={checkLocationErrors(reject.id, 'kp_start')}
          value={getLocationValue(reject.id, 'kp_start') as string}
          onBlur={newValue => {
            if (String(newValue) !== String(getLocationValue(reject.id, 'kp_start'))) {
              updateValue(['locations', 'kp_start'], newValue as string, reject as RejectDetails)
            }
          }}
          endAdornment={hasMultipleLocations(reject as RejectDetails)
            ? <div className="adornment">m*</div> : <div className="adornment">m</div>}
        />
      )
    },
  },
  {
    propertyName: 'locations',
    title: 'kpEnd',
    field: 'kp_end',
    align: 'left',
    width: '150px',
    filterOperators: FILTERED_STRING_OPERATORS,
    formatter: (_o: unknown, _users: unknown, reject: Reject): ReactElement => {
      return (
        <CustomTextField
          type="number"
          error={checkLocationErrors(reject.id, 'kp_end')}
          value={getLocationValue(reject.id, 'kp_end') as string}
          onBlur={newValue => {
            if (String(newValue) !== String(getLocationValue(reject.id, 'kp_end'))) {
              updateValue(['locations', 'kp_end'], newValue as string, reject as RejectDetails)
            }
          }}
          endAdornment={hasMultipleLocations(reject as RejectDetails)
            ? <div className="adornment">m*</div> : <div className="adornment">m</div>}
        />
      )
    },
  },
  {
    propertyName: 'protection_name',
    title: 'protectionName',
    field: 'protection_name',
    align: 'left',
    width: '350px',
    formatter: (o): string => o as string,
    filterOperators: FILTERED_STRING_OPERATORS,
  },
  {
    propertyName: 'error_code',
    title: 'errorCode',
    field: 'error_code',
    align: 'left',
    width: '150px',
    formatter: (o): string => o as string,
    filterOperators: FILTERED_STRING_OPERATORS,
  },
  {
    propertyName: 'error_reason',
    title: 'errorReason',
    field: 'error_reason',
    align: 'left',
    width: '200px',
    formatter: (o): string => o as string,
    filterOperators: FILTERED_STRING_OPERATORS,
  },
  {
    propertyName: 'sheet_rejected',
    title: 'sheetRejected',
    field: 'sheet_rejected',
    align: 'left',
    width: '100px',
    formatter: (o: unknown): string => { return o ? 'Oui' : 'Non' },
    filterOperators: FILTERED_STRING_OPERATORS,
  },
  {
    propertyName: 'status',
    title: 'status',
    field: 'status',
    align: 'left',
    width: '250px',
    formatter: (o: unknown): ReactElement => {
      return (<CustomStatusBadge value={o as string} />)
    },
    filterOperators: FILTERED_STRING_OPERATORS,
  },
  {
    propertyName: 'previous_analysis',
    title: 'previousAnalysis',
    field: 'previous_analysis',
    align: 'left',
    width: '350px',
    formatter: (o): string => o as string,
    filterOperators: FILTERED_STRING_OPERATORS,
  },
  {
    propertyName: 'current_analysis',
    title: 'currentAnalysis',
    field: 'current_analysis',
    align: 'left',
    width: '300px',
    formatter: (o: unknown, _users: unknown, reject: Reject): ReactElement => {
      return (
        <CustomDraggableCell
          reject={reject}
          items={ANALYSIS_LIST}
          onChange={newValue => {
            if (newValue !== o as string) updateValue('current_analysis', newValue as string, reject as RejectDetails)
          }}
        />
      )
    },
    filterOperators: FILTERED_STRING_OPERATORS,
  },
  {
    propertyName: 'reject_type',
    title: 'rejectType',
    field: 'reject_type',
    align: 'left',
    width: '150px',
    formatter: (o: unknown, _users: unknown, reject: Reject): ReactElement => {
      return (
        <CustomSelect
          value={o as string}
          items={REJECT_TYPE}
          onChange={newValue => {
            if (newValue !== o as string) updateValue('reject_type', newValue as string, reject as RejectDetails)
          }}
        />
      )
    },
    filterOperators: FILTERED_STRING_OPERATORS,
  },
  {
    propertyName: 'free_comment',
    title: 'freeComment',
    field: 'free_comment',
    align: 'left',
    width: '300px',
    filterOperators: FILTERED_STRING_OPERATORS,
    formatter: (o: unknown, _users: unknown, reject: Reject): ReactElement => {
      return (
        <CustomTextField
          value={o as string}
          onBlur={newValue => {
            if (newValue !== o as string) {
              updateValue('free_comment', newValue as string, reject as RejectDetails)
            }
          }}
        />
      )
    },
  },
  {
    propertyName: 'sheet',
    title: 'reaccord',
    field: 'reaccord',
    align: 'left',
    width: '130px',
    filterOperators: getGridBooleanOperators(),
    formatter: (o: unknown, _users: unknown, reject: Reject): ReactElement => {
      const row = reject as RejectDetails
      return (
        <CustomCheckbox
          value={(o as Sheet).reaccord}
          onChange={newValue => {
            updateSheet(row, newValue)
          }}
        />
      )
    },
  },
  {
    propertyName: 'analysis_by',
    title: 'analysisBy',
    field: 'analysis_by',
    align: 'left',
    width: '200px',
    filterOperators: FILTERED_STRING_OPERATORS,
    formatter: (o: unknown, users: unknown): string => {
      return (convertIdToName(users as User[], o as string))
    },
  },
  {
    propertyName: 'import_date',
    title: 'importDate',
    field: 'import_date',
    align: 'left',
    width: '200px',
    filterOperators: FILTERED_STRING_OPERATORS,
    formatter: (o: unknown): string => {
      return formatSimpleDatetime(o as string)
    },
  },
]

export { columnsHeader, ANALYSIS_LIST }
