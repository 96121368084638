/* eslint-disable array-callback-return */
/* eslint-disable no-plusplus */
/* eslint-disable no-lonely-if */
/* eslint-disable camelcase */
import AddIcon from '@mui/icons-material/Add'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import CloseIcon from '@mui/icons-material/Close'
import FilterAltIcon from '@mui/icons-material/FilterAlt'
import RemoveIcon from '@mui/icons-material/Remove'
import {
  Button, Checkbox, IconButton,
} from '@mui/material'
import { useTranslation } from '@osrdata/app_core/dist/translation'
import FeedbackSnackBar from 'components/Common/FeedbackSnackBar/FeedbackSnackBar'
import InfoPanel, { Severity } from 'components/Common/InfoPanel/InfoPanel'
import CustomSelect from 'components/Common/Inputs/CustomSelect/CustomSelect'
import CustomTextField from 'components/Common/Inputs/CustomTextField/CustomTextField'
import Loader from 'components/Common/Loader'
import ObjectsList from 'components/Common/ObjectsList/ObjectsList'
import SheetStatusBadge from 'components/Common/SheetStatusBadge/SheetStatusBadge'
import { ANALYSIS_LIST, REJECT_TYPE } from 'components/CustomTable/const'
import HistoryList from 'components/HistoryList/HistoryList'
import { formatDate } from 'components/ImportHome/utils'
import { snakeToCamel } from 'helpers'
import { Location, RejectDetails } from 'objects/types'
import {
  ChangeEvent, ReactElement, useEffect, useRef, useState,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setError, setMessage } from 'reducers/feedback'
import {
  addCurrentRejectLocation,
  GrefState,
  removeCurrentRejectLocation,
  removeFilter,
  setCurrentRejectField, setFilterMenu, setIndex, setPageNumber,
} from 'reducers/grefs'
import GrefServices from 'services/GrefServices'
import { RootState } from 'Store'
import sheetInfo from './const'
import FilterSelect from './FilterSelect'
import './RejectDataView.scss'

type Props = {
  rejectId: number | null;
}

export default function RejectDataView({ rejectId }: Props): ReactElement {
  const dispatch = useDispatch()
  const {
    rejects, currentReject, sheets, sheetCount, filters, currentIndex,
    pageNumber, currentRejectLoading, sheetsLoading, filterMenuOpen, sort,
  } = useSelector((state: RootState) => state.grefs) as GrefState
  const { currentEic } = useSelector((state: RootState) => state.eics)
  const { t } = useTranslation()
  const [ind, setCurrentIndex] = useState(0)
  const init = useRef<boolean>(false)

  const compIndex = (rej: string) => {
    // eslint-disable-next-line @typescript-eslint/no-shadow
    const result = sheets.flatMap(({ rejects }) => rejects)
    const indexOf = result.findIndex(elem => elem.id === rej)
    return indexOf + 1
  }

  const updateSheets = () => {
    dispatch(GrefServices.getSheets({
      eic: currentEic, page: 1, filterItems: filters, appendSheets: false,
    }))
  }

  useEffect(() => {
    updateSheets()
  }, [filters])

  useEffect(() => {
    if (sheets.length && init.current === false) {
      init.current = true
      if (rejectId) {
        for (let i = 0; i < sheets.length; i++) {
          for (let j = 0; j < sheets[i].rejects.length; j++) {
            if (String(sheets[i].rejects[j]?.id) === String(rejectId)) {
              dispatch(setIndex({ sheet: i, reject: j }))
            }
          }
        }
      } else if (sheets.length) {
        dispatch(GrefServices.getReject({
          eic: currentEic,
          id: sheets[currentIndex.sheet].rejects[currentIndex.reject].id,
        }))
      }
    } else {
      dispatch(setIndex({ sheet: 0, reject: 0 }))
    }
  }, [sheets])

  useEffect(() => {
    if (sheets.length) {
      const selectedSheet = sheets[currentIndex.sheet]
      const reject = selectedSheet?.rejects[currentIndex.reject]
      if (reject !== undefined) {
        if (reject.id === currentReject.id) {
          return
        }
        dispatch(GrefServices.getReject({
          eic: currentEic,
          id: reject.id,
        }))
      }
    }
  }, [currentIndex])

  useEffect(() => {
    const value = compIndex(currentReject.id)
    setCurrentIndex(value)
  }, [sheets, currentReject])

  const onChangeReaccord = async (_event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
    await dispatch(GrefServices.updateSheet({
      eic_zone: currentReject.eic_zone,
      id: currentReject.sheet.id,
      date: currentReject.sheet.date,
      reaccord: checked,
      rejectId: currentReject.id,
    }))
    dispatch(GrefServices.getRejects({ eic: currentEic, filterItems: filters, sortItem: sort }))
    dispatch(GrefServices.getReject({
      eic: currentEic,
      id: currentReject.id,
    }))
  }

  const updateField = (path: string | string[], value: string) => {
    dispatch(setCurrentRejectField({ path, value }))
  }

  const locationsFilled = () => {
    let flag = true
    currentReject.locations.some(elem => {
      Object.keys(elem).forEach(p => {
        const prop = p as keyof Location
        if (prop !== 'id') {
          if (['', null, undefined].includes(elem[prop])) {
            flag = false
          }
        }
      })
    })
    currentReject.locations.some(elem => {
      if (elem.kp_start && elem.kp_end) {
        if (Number(elem.kp_start) >= Number(elem.kp_end)) {
          flag = false
        }
      }
    })
    return flag
  }

  const checkLocationErrors = (location: Location, field: string): boolean => {
    if (!location[field as keyof Location]) {
      return true
    }
    if (location.kp_start && location.kp_end) {
      if (Number(location.kp_start) >= Number(location.kp_end)) {
        return true
      }
    }

    return false
  }

  const displayKpError = (location: Location) => {
    if (location.kp_start && location.kp_end) {
      if (+location.kp_start >= +location.kp_end) {
        dispatch(setError({ code: 0, data: { message: 'TreatRejects.feedback.kpError' } }))
      }
    }
  }

  const handleSave = () => {
    if (locationsFilled()) {
      dispatch(GrefServices.updateReject(currentReject))
    }
    dispatch(setMessage('TreatRejects.dataView.fillAllFields'))
  }

  const handleNavSheet = (direction: string) => {
    if (direction === 'next') {
      if (currentIndex.reject + 1 === sheets[currentIndex.sheet].rejects.length) {
        if (currentIndex.sheet + 1 === sheets.length) {
          dispatch(GrefServices.getSheets({ eic: currentEic, page: pageNumber + 1, filterItems: filters }))
          dispatch(setPageNumber(pageNumber + 1))
        }
        dispatch(setIndex({ sheet: currentIndex.sheet + 1, reject: 0 }))
      } else {
        dispatch(setIndex({ sheet: currentIndex.sheet, reject: currentIndex.reject + 1 }))
      }
    } else {
      if (currentIndex.reject === 0) {
        const index = sheets[currentIndex.sheet - 1].rejects.length - 1
        dispatch(setIndex({ sheet: currentIndex.sheet - 1, reject: index }))
      } else {
        dispatch(setIndex({ sheet: currentIndex.sheet, reject: currentIndex.reject - 1 }))
      }
    }
  }

  return (
    <div className="data-view-container">
      <div className="d-flex w-100 sheet-list">
        <div className="sheet-list-button col-2">
          <div className="d-flex justify-content-between align-items-center">
            <div>
              <div className="sheet">{t('TreatRejects.dataView.sheetDp')}</div>
              <div id="line-number">
                {`${sheetCount} ${t('TreatRejects.dataView.lines')}`}
              </div>
            </div>
            <div>
              <IconButton
                onClick={() => dispatch(setFilterMenu(!filterMenuOpen))}
              >
                <FilterAltIcon className="filter-icon" />
              </IconButton>
              {filterMenuOpen && (
                <div style={{ position: 'relative', zIndex: 20 }}>
                  <FilterSelect />
                </div>
              )}
            </div>
          </div>
          <div className="filter-container">
            {filters.map(filter => (
              filter.value !== null && filter.value !== undefined && filter.value !== '' ? (
                <div key={filter.id} className="sheet-filter d-flex justify-content-center align-items-center">
                  <div className="filter-text">
                    {t(`TreatRejects.table.${snakeToCamel(filter.columnField)}`)}
                    {' '}
                    {t(`TreatRejects.filters.${filter.operatorValue}`)}
                    {' '}
                    <strong>{filter.value}</strong>
                  </div>
                  <div>
                    <IconButton
                      onClick={() => dispatch(removeFilter(filter))}
                    >
                      <CloseIcon className="close-button" />
                    </IconButton>
                  </div>
                </div>
              )
                : null
            ))}
          </div>
          <ObjectsList items={sheets} />
        </div>
        <div className="col-7 sheet-info">
          {currentRejectLoading || (sheets.length === 0 && sheetsLoading) ? (
            <div className="h-100 w-100 d-flex justify-content-center align-items-center" style={{ marginTop: '25vh' }}>
              <Loader />
            </div>
          ) : (
            <>
              {(sheets.length === 0 && init.current)
                ? (
                  <InfoPanel
                    title={t('TreatRejects.noReject.title')}
                    severity={Severity.warning}
                  />
                )
                : (
                  <>
                    <div className="d-flex justify-content-between">
                      <div className="d-flex w-100">
                        <div className="w-100 info-title">
                          <div className="d-flex container-title w-100">
                            <h1
                              title={currentReject.protection_name}
                              className="protection-title"
                            >
                              {currentReject.protection_name}
                            </h1>
                          </div>

                          <h3 className="serial-title">{currentReject.sheet.serial}</h3>
                        </div>
                        <div className="d-flex row justify-content-end w-100">
                          <SheetStatusBadge value={currentReject.status} />
                          <div>
                            <div className="d-flex justify-content-center align-items-center">
                              <IconButton
                                className="sheet-nav-button mx-2"
                                aria-label="previous"
                                onClick={() => handleNavSheet('prev')}
                                disabled={currentIndex.reject === 0 && currentIndex.sheet === 0}
                              >
                                <ChevronLeftIcon />
                              </IconButton>

                              <IconButton
                                className="sheet-nav-button"
                                aria-label="next"
                                sx={{ marginRight: '12px' }}
                                onClick={() => handleNavSheet('next')}
                                disabled={ind >= Number(rejects.count)}
                              >
                                <ChevronRightIcon />
                              </IconButton>
                            </div>
                            <div id="lineNb">
                              {`${ind} ${t('TreatRejects.dataView.on')} ${rejects.count}`}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="sheet-description">
                      <fieldset>
                        <legend id="sheet-fieldset">{t('TreatRejects.dataView.sheetDescription')}</legend>
                      </fieldset>

                      <div className="sheet-form-info">
                        {sheetInfo.map(sheet => (
                          <div key={sheet.label} className="sheet-form-unit justify-content-start">
                            <span className="sheet-details-label">{t(`TreatRejects.table.${sheet.label}`)}</span>
                            <span className="w-50">
                              {sheet.formatter(currentReject[sheet.fieldName as keyof RejectDetails])}
                            </span>
                          </div>
                        ))}

                      </div>

                      <div id="sheet-analysis">
                        <div className="wrapper">
                          <span className="label-analysis">{t('TreatRejects.table.currentAnalysis')}</span>
                          <CustomSelect
                            value={currentReject.current_analysis}
                            items={ANALYSIS_LIST}
                            onChange={newValue => updateField('current_analysis', newValue)}
                          />
                        </div>
                        <div className="wrapper">
                          <span className="label-analysis">{t('TreatRejects.table.freeComment')}</span>
                          <CustomTextField
                            value={currentReject.free_comment}
                            onChange={newValue => updateField('free_comment', newValue)}
                          />
                        </div>

                        <div className="d-flex w-100 sheet-lines">
                          <div className="d-flex w-100 justify-content-between align-items-center">
                            <span id="location-table">{t('TreatRejects.dataView.locationForTable')}</span>
                            <IconButton
                              className="add-location"
                              onClick={() => dispatch(addCurrentRejectLocation())}
                              aria-label="add"
                              component="span"
                            >
                              <AddIcon />
                            </IconButton>
                          </div>

                          {currentReject.locations.map((location, index) => (
                            // eslint-disable-next-line react/no-array-index-key
                            <div key={index} className="d-flex locations justify-content-between">
                              <div className="wrapper-location">
                                <span className="label-analysis">{t('TreatRejects.table.lineCode')}</span>
                                <CustomTextField
                                  error={['', null, undefined].includes(location.line_code)}
                                  value={location.line_code || ''}
                                  maxLength={10}
                                  onChange={newValue => updateField(['locations', String(index), 'line_code'],
                                    newValue)}
                                />
                                {['', null, undefined].includes(location.line_code)
                                  ? (<p className="error-message">{t('TreatRejects.dataView.fillFields')}</p>) : ''}

                              </div>

                              <div className="wrapper-location">
                                <span className="label-analysis">{t('TreatRejects.table.trackName')}</span>
                                <CustomTextField
                                  error={['', null, undefined].includes(location.track_name)}
                                  value={location.track_name || ''}
                                  maxLength={10}
                                  onChange={newValue => updateField(['locations', String(index), 'track_name'],
                                    newValue)}
                                />
                                {['', null, undefined].includes(location.track_name)
                                  ? (<p className="error-message">{t('TreatRejects.dataView.fillFields')}</p>) : ''}

                              </div>

                              <div className="wrapper-location">
                                <span className="label-analysis">{t('TreatRejects.table.kpStart')}</span>
                                <CustomTextField
                                  error={checkLocationErrors(location, 'kp_start')}
                                  value={location.kp_start || ''}
                                  type="number"
                                  onBlur={() => displayKpError(location)}
                                  onChange={newValue => updateField(['locations', String(index), 'kp_start'], newValue)}
                                  endAdornment={<div>m</div>}
                                />
                                {['', null, undefined].includes(location.kp_start)
                                  ? (<p className="error-message">{t('TreatRejects.dataView.fillFields')}</p>) : ''}

                              </div>

                              <div className="wrapper-location">
                                <span className="label-analysis">{t('TreatRejects.table.kpEnd')}</span>
                                <CustomTextField
                                  value={location.kp_end || ''}
                                  type="number"
                                  error={checkLocationErrors(location, 'kp_end')}
                                  onBlur={() => displayKpError(location)}
                                  onChange={newValue => updateField(['locations', String(index), 'kp_end'], newValue)}
                                  endAdornment={<div>m</div>}
                                />
                                {['', null, undefined].includes(location.kp_end)
                                  ? (<p className="error-message">{t('TreatRejects.dataView.fillFields')}</p>) : ''}

                              </div>

                              <div className="wrapper-locatiosn" key={location.kp_end}>
                                <span className="label-analysis" style={{ visibility: 'hidden' }} />
                                <IconButton
                                  color="primary"
                                  className="add-location"
                                  onClick={() => dispatch(removeCurrentRejectLocation(index))}
                                  aria-label="remove"
                                  component="span"
                                >
                                  <RemoveIcon />
                                </IconButton>
                              </div>
                            </div>
                          ))}
                        </div>

                        <div className="wrapper">
                          <Checkbox
                            sx={{ paddingLeft: '0px' }}
                            checked={currentReject.sheet.reaccord}
                            onChange={onChangeReaccord}
                          />
                          <span id="reaccord">{t('TreatRejects.table.reaccord')}</span>
                        </div>

                        <div className="wrapper">
                          <span className="label-analysis">{t('TreatRejects.table.rejectType')}</span>
                          <CustomSelect
                            value={currentReject.reject_type}
                            items={REJECT_TYPE}
                            onChange={newValue => updateField('reject_type', newValue)}
                          />
                        </div>

                        <Button
                          id="sheet-save"
                          onClick={handleSave}
                          disabled={!locationsFilled()}
                          variant="contained"
                        >
                          {t('TreatRejects.dataView.saveObject')}
                        </Button>
                      </div>

                    </div>
                  </>
                )}
            </>
          )}
        </div>
        <div className="col-3 history">
          <div className="px-3">
            <span className="history-label">{t('TreatRejects.dataView.history')}</span>
            {sheets.length < 1 && ('')}
            {(currentRejectLoading || (sheets.length === 0 && sheetsLoading)) && (
              <div
                style={{ minHeight: '65vh', maxHeight: '65vh' }}
                className=" d-flex justify-content-center align-items-center"
              >
                <Loader />
              </div>
            )}
            {!currentRejectLoading && currentReject.history.length > 0 && sheets.length > 0 && (
              <>
                <span className="history-sub-label">
                  {`${t('TreatRejects.dataView.lastUpdate')} 
                    ${formatDate(currentReject.history[0]?.action_date)}`}
                </span>
                <HistoryList rejectDetails={currentReject} />
              </>
            )}
          </div>
        </div>
      </div>
      <FeedbackSnackBar />
    </div>
  )
}
