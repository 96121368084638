import { Button, SxProps } from '@mui/material'
import { ReactElement, ReactNode } from 'react'

export enum ButtonState {
  active = 'active',
  inactive = 'inactive',
}

type Props = {
  title: string;
  onClick: () => void;
  style?: ButtonState;
  customStyle?: SxProps;
  disabled?: boolean;
  startIcon?: ReactNode;
} & typeof defaultProps

const defaultProps = {
  style: ButtonState.active,
  customStyle: {} as SxProps,
  disabled: false,
  startIcon: <></>,
}

const activeStyle: SxProps = {
  backgroundColor: '#41A8F9',
  color: '#F5F6F6',
  fontSize: 16,
  fontFamily: 'Avenir',
  fontWeight: 900,
  paddingTop: '10px',
  marginLeft: '20px',
  borderRadius: '8px',
  maxWidth: '450px',
  ':focus': {
    outline: 'none',
  },
}

const inactiveStyle: SxProps = {
  backgroundColor: 'white',
  fontFamily: 'Avenir',
  fontWeight: 900,
  paddingTop: '10px',
  marginLeft: '20px',
  borderRadius: '8px',
  maxWidth: '450px',
  color: '#414141',
  fontSize: 16,
  boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.08), 0px 1px 4px rgba(0, 0, 0, 0.06)',
  ':hover': {
    backgroundColor: '#EEEEEE',
    color: '#50504F',
  },
  ':focus': {
    outline: 'none',
  },
}

export default function RejectButton({
  title, disabled, style, customStyle, onClick, startIcon,
}: Props): ReactElement {
  const getCustomStyle = () => {
    if (customStyle !== null && Object.keys(customStyle).length !== 0) {
      return customStyle
    }
    if (style) {
      switch (style) {
        case ButtonState.inactive:
          return inactiveStyle
        case ButtonState.active:
          return activeStyle
        default:
          break
      }
    }
    return inactiveStyle
  }

  return (
    <Button
      className="button"
      variant="contained"
      size="large"
      sx={{
        textTransform: 'none',
        width: 'auto',
        boxShadow: 0,
        ...getCustomStyle(),
      }}
      disabled={disabled}
      startIcon={startIcon}
      onClick={onClick}
    >
      {title}
    </Button>
  )
}

RejectButton.defaultProps = defaultProps
