import { Chip } from '@mui/material'
import { ReactElement } from 'react'
import {
  doneStyle, exportStyle, failedStyle, ocaStyle, pendingStyle, Status, treatStyle,
} from './const'
import './SheetStatusBadge.scss'

type Props = {
  value: string;
}

export default function SheetStatusBadge({ value }: Props): ReactElement {
  const getCustomStyle = () => {
    switch (value) {
      case Status.treat:
        return treatStyle

      case Status.failed:
        return failedStyle

      case Status.pending:
        return pendingStyle

      case Status.export:
        return exportStyle

      case Status.done:
        return doneStyle

      case Status.oca:
        return ocaStyle

      default:
        return failedStyle
    }
  }
  return (
    <Chip sx={{ ...getCustomStyle() }} className="sheet-status-chip" label={value || 'Statut inconnu'} />
  )
}
