import { useTranslation } from '@osrdata/app_core/dist/translation'
import RejectButton, { ButtonState } from 'components/Common/RejectButton/RejectButton'
import HistoryIcon from '@mui/icons-material/History'
import PreviewIcon from '@mui/icons-material/Preview'
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined'
import { ReactElement } from 'react'
import './ExportHeader.scss'
import { RootState } from 'Store'
import { useSelector } from 'react-redux'

type Props = {
  currentTab: number;
  handleChange: (e: number) => void;
}
export default function ExportHeader({ currentTab, handleChange }: Props): ReactElement {
  const { t } = useTranslation()
  const { csvContent, newExport } = useSelector((state: RootState) => state.grefs)

  return (
    <div className="d-flex w-100 export-header justify-content-between">
      <div className="w-100 h-100 export-header-titles justify-content-between align-items-between">
        {currentTab === 0 && <span className="export-main-title">{t('ExportRejects.table.exportHistory')}</span>}
        {currentTab === 1 && <span className="export-main-title">{t('ExportRejects.table.exportPreview')}</span>}
        {currentTab === 2 && <span className="summary-main-title">{t('ExportRejects.table.sheetSummary')}</span>}
        {currentTab !== 2 && <span className="export-subtitle">{t('ExportRejects.table.forTable')}</span>}
      </div>
      <div className="w-100 d-flex export-header-buttons justify-content-end">
        <RejectButton
          startIcon={<HistoryIcon />}
          style={currentTab === 0 ? ButtonState.active : ButtonState.inactive}
          title={t('ExportRejects.table.exportHistory')}
          onClick={() => handleChange(0)}
        />

        <RejectButton
          startIcon={<PreviewIcon />}
          style={currentTab === 1 ? ButtonState.active : ButtonState.inactive}
          title={t('ExportRejects.table.exportPreview')}
          onClick={() => handleChange(1)}
        />

        {csvContent !== '' && newExport.id != null && (
          <RejectButton
            startIcon={<DescriptionOutlinedIcon />}
            style={currentTab === 2 ? ButtonState.active : ButtonState.inactive}
            title={t('ExportRejects.table.sheetSummary')}
            onClick={() => handleChange(2)}
          />
        )}

      </div>

    </div>
  )
}
