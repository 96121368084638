import { DateTime } from 'luxon'

const formatSimpleDatetime = (date: string): string => (
  DateTime.fromISO(date).setLocale('fr').toFormat('dd/MM/yyyy HH:mm')
)

const formatSimpleDate = (date: string): string => (
  DateTime.fromISO(date).setLocale('fr').toFormat('dd/MM/yyyy')
)

export { formatSimpleDatetime, formatSimpleDate }
