/* eslint-disable camelcase */
import { Pagination, TableBody, TableCell } from '@mui/material'
import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import { useTranslation } from '@osrdata/app_core/dist/translation'
import InfoPanel, { Severity } from 'components/Common/InfoPanel/InfoPanel'
import Loader from 'components/Common/Loader'
import ExportHeader from 'components/ExportHeader/ExportHeader'
import ExportPreview from 'components/ExportPreview/ExportPreview'
import SheetReaccord from 'components/SheetReaccord/SheetReaccord'
import { Export } from 'objects/types'
import {
  ChangeEvent,
  ReactElement, useEffect, useRef, useState,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { GrefState, resetCsv } from 'reducers/grefs'
import GrefServices from 'services/GrefServices'
import UserServices from 'services/UserServices'
import { User } from 'services/UserServices/cerbereTypes'
import { RootState } from 'Store'
import COLUMNS from './const'
import './ExportHome.scss'

export default function ExportHome(): ReactElement {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { exports, grefsLoading, newExport } = useSelector((state: RootState) => state.grefs) as GrefState
  const userList = useSelector((state: RootState) => state.grefs.userList) as User[]
  const tableRef = useRef<HTMLTableElement>(null)
  const [activeTab, setActiveTab] = useState(0)
  const [page, setPage] = useState(1)
  const { currentEic } = useSelector((state: RootState) => state.eics)
  const { count, page_size } = useSelector((state: RootState) => state.grefs.exports)
  const pagesNumber = Math.ceil(count / page_size) || 1

  useEffect(() => {
    dispatch(GrefServices.getExports({ eic: currentEic, page }))
  }, [])

  useEffect(() => {
    const usersId: string[] = []
    exports.results.forEach((ex: Export) => {
      if (usersId.indexOf(ex.exported_by) === -1) {
        usersId.push(ex.exported_by)
      }
    })

    dispatch(UserServices.getUsersListInfo(usersId))
  }, [exports])

  useEffect(() => {
    if (activeTab === 2) {
      setActiveTab(0)
    }
    dispatch(resetCsv())
  }, [currentEic])

  const handleChangeTab = (newTab: number) => {
    if (newTab === 0 && activeTab !== 0) {
      dispatch(GrefServices.getExports({ eic: currentEic, page }))
    }
    setActiveTab(newTab)
  }

  const handleChangePage = (_event: ChangeEvent<unknown> | null, newPage: number) => {
    if (tableRef.current) {
      tableRef.current.scrollTo(0, 0)
    }
    setPage(newPage)
  }

  useEffect(() => {
    if (newExport.id) {
      if (page === 1) {
        dispatch(GrefServices.getExports({ eic: currentEic, page }))
      } else {
        setPage(1)
      }
    }
  }, [newExport])

  useEffect(() => {
    if (page === 1) {
      dispatch(GrefServices.getExports({ eic: currentEic, page }))
    } else {
      setPage(1)
    }
  }, [currentEic])

  useEffect(() => {
    dispatch(GrefServices.getExports({ eic: currentEic, page }))
  }, [page])

  return (
    <Paper className="main-export-container">
      <ExportHeader currentTab={activeTab} handleChange={handleChangeTab} />
      {activeTab === 0 && grefsLoading && (
        <>
          <div
            style={{ minHeight: '65vh', maxHeight: '65vh' }}
            className=" d-flex justify-content-center align-items-center"
          >
            <Loader />
          </div>
          <div className="d-flex footer align-items-end justify-content-center">
            <Pagination
              onChange={handleChangePage}
              variant="outlined"
              page={page}
              shape="rounded"
              boundaryCount={1}
              siblingCount={1}
              disabled
              count={pagesNumber}
            />
          </div>
        </>
      )}
      {activeTab === 0 && exports.results.length > 0 && !grefsLoading && (
        <>
          <TableContainer
            ref={tableRef}
            className="table-export-container"
            sx={{ minWidth: 650, height: 'calc(100vh - 300px)' }}
          >
            <Table stickyHeader aria-label="simple table">
              <colgroup>
                <col style={{ width: '35%' }} />
                <col style={{ width: '20%' }} span={2} />
                <col style={{ width: '30%' }} />
              </colgroup>
              <TableHead>
                <TableRow>
                  {COLUMNS.map(header => (
                    <TableCell
                      key={header.title}
                      align={header.align}
                    >
                      {t(header.title)}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {(exports.results).map(ex => (
                  <TableRow
                    key={ex.id}
                  >

                    {COLUMNS.map(column => (
                      <TableCell key={column.title} align={column.align}>
                        {column.formatter(ex[column.propertyName], userList)}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}

              </TableBody>
            </Table>
          </TableContainer>
          <div className="d-flex footer align-items-end justify-content-center">
            <Pagination
              onChange={handleChangePage}
              variant="outlined"
              page={page}
              shape="rounded"
              boundaryCount={1}
              siblingCount={1}
              count={pagesNumber}
            />
          </div>
        </>
      )}

      {activeTab === 0 && exports.results.length === 0 && !grefsLoading
        && (
          <InfoPanel
            title={t('ExportRejects.noHistory.title')}
            severity={Severity.warning}
          />
        )}

      {activeTab === 1 && <ExportPreview />}

      {activeTab === 2 && <SheetReaccord />}
    </Paper>

  )
}
