import {
  IconButton,
  TableCell, TableHead, TableRow, TableSortLabel,
} from '@mui/material'
import { GridFilterAltIcon } from '@mui/x-data-grid'
import { useTranslation } from '@osrdata/app_core/dist/translation'
import { Order } from 'objects/types'
import { ReactElement, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { EicState } from 'reducers/eics'
import { GrefState, setFilterMenu, setSort } from 'reducers/grefs'
import GrefServices, { FilterItem } from 'services/GrefServices'
import { RootState } from 'Store'
import { Column } from './const'
import CustomMenu from './CustomMenu'
import './CustomTableHead.scss'

type Props = {
  pinnedCols: Column[];
  notPinnedCols: Column[];
}

export default function CustomTableHead(props: Props): ReactElement {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const {
    filters, filterMenuOpen, sort,
  } = useSelector((state: RootState): GrefState => state.grefs)
  const { currentEic } = useSelector((state: RootState) => state.eics) as EicState
  const [isHovered, setHover] = useState<string>('')
  const { pinnedCols, notPinnedCols } = props
  const [offsetLeft, setOffset] = useState<string[]>([])
  const [menuOpened, setMenuState] = useState<string | null>(null)

  const handleMouseOver = (field: string) => {
    if (!isHovered) {
      setHover(field)
    }
  }

  useEffect(() => {
    const wid: string[] = []
    pinnedCols.forEach((_col, index) => {
      if (index === 0) {
        wid.push('0')
        return
      }
      const val = Number(Number(wid[index - 1].slice(0, -2)) + Number(pinnedCols[index - 1].width.slice(0, -2)))
      wid.push(String(val).concat('px'))
    })
    setOffset(wid)
  }, [pinnedCols])

  const handleMouseOut = () => {
    setHover('')
  }

  const handleSort = (column: string) => {
    let order: Order = 'asc'
    let field = column
    if (sort.field === column) {
      switch (sort.sort) {
        case 'asc':
          order = 'desc'
          break
        case 'desc':
          order = undefined
          field = ''
          break
        default:
          order = 'asc'
      }
    } else {
      order = 'asc'
    }

    dispatch(setSort({ field, sort: order }))
    dispatch(GrefServices.getRejects({
      eic: currentEic,
      filterItems: filters,
      sortItem: { field, sort: order },
    }))
  }

  const setMenu = (open: string | null) => {
    if (open === null) {
      setHover('')
    }
    setMenuState(open)
  }

  const computeHeaderWidth = (field: string) => {
    if ((isHovered === field || menuOpened === field)) {
      if (filters.findIndex((elem: FilterItem) => elem.columnField as string === field
        && elem.value !== '') !== -1) {
        return '30%'
      }
      return '55%'
    }
    if (filters.findIndex((elem: FilterItem) => elem.columnField as string === field
      && elem.value !== '') !== -1) {
      return '65%'
    }
    return '100%'
  }

  return (
    <TableHead>
      <TableRow>
        {pinnedCols.map((headCell: Column, index) => (
          <TableCell
            onMouseOver={() => handleMouseOver(headCell.field)}
            onMouseOut={handleMouseOut}
            key={headCell.field}
            align="left"
            padding="none"
            sx={{
              position: 'sticky',
              zIndex: 1500,
              width: headCell.width,
              minWidth: headCell.width,
              left: `${offsetLeft[index]} !important`,
              backgroundColor: 'white',
            }}
            title={t(`TreatRejects.table.${headCell.title}`)}
          >
            <TableSortLabel
              sx={{
                minWidth: '20%',
                maxWidth: computeHeaderWidth(headCell.field),
              }}
              active={sort.field === headCell.field}
              direction={sort.field === headCell.field ? sort.sort : undefined}
              onClick={() => handleSort(headCell.field)}
            >
              <span style={{
                display: 'block',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                maxWidth: '75%',
                overflow: 'hidden',
              }}
              >
                {t(`TreatRejects.table.${headCell.title}`)}
              </span>
            </TableSortLabel>
            {filters.findIndex((elem: FilterItem) => elem.columnField as string === headCell.field
              && elem.value !== '') !== -1
              ? (
                <IconButton size="small" onClick={() => dispatch(setFilterMenu(!filterMenuOpen))}>
                  <GridFilterAltIcon className="filter-icon" />
                </IconButton>
              ) : null}
            <CustomMenu setMenu={setMenu} column={headCell.field} visible={isHovered} />
          </TableCell>
        ))}
        {notPinnedCols.map((headCell: Column) => (
          <TableCell
            onMouseOver={() => handleMouseOver(headCell.field)}
            onMouseOut={handleMouseOut}
            key={headCell.field}
            align="left"
            padding="none"
            sx={{ minWidth: headCell.width, maxWidth: headCell.width }}
            title={t(`TreatRejects.table.${headCell.title}`)}
          >
            <TableSortLabel
              sx={{
                minWidth: '20%',
                maxWidth: computeHeaderWidth(headCell.field),
              }}
              active={sort.field === headCell.field}
              direction={sort.field === headCell.field ? sort.sort : undefined}
              onClick={() => handleSort(headCell.field)}
            >
              <span style={{
                display: 'block',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                maxWidth: '85%',
                overflow: 'hidden',
              }}
              >
                {t(`TreatRejects.table.${headCell.title}`)}
              </span>
            </TableSortLabel>
            {filters.findIndex((elem: FilterItem) => elem.columnField as string === headCell.field
              && elem.value !== '') !== -1
              ? (
                <IconButton size="small" onClick={() => dispatch(setFilterMenu(!filterMenuOpen))}>
                  <GridFilterAltIcon className="filter-icon" />
                </IconButton>
              ) : null}
            <CustomMenu setMenu={setMenu} column={headCell.field} visible={isHovered} />
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}
