import { DateTime } from 'luxon'
import { User } from 'services/UserServices/cerbereTypes'

const formatDate = (date: string): string => (
  DateTime.fromISO(date).setLocale('fr').toFormat("dd/MM/yyyy à HH'h'mm")
)

const convertIdToName = (userList: User[], id: string, toLowerCase = false): string => {
  const userInfo = userList.find(user => user.id === (id))
  if (userInfo) {
    if (toLowerCase && userInfo.lastName) {
      const name = userInfo.lastName.charAt(0) + userInfo.lastName.substring(1).toLowerCase()
      return `${userInfo.firstName} ${name}`
    }
    return `${userInfo.firstName} ${userInfo.lastName}`
  }
  return 'Personne inconnue'
}

export { formatDate, convertIdToName }
