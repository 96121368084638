/* eslint-disable arrow-body-style */

import CustomSelect from 'components/Common/Inputs/CustomSelect/CustomSelect'
import CustomTextField from 'components/Common/Inputs/CustomTextField/CustomTextField'
import {
  checkLocationErrors, Column, FILTERED_STRING_OPERATORS, getLocationValue,
  hasMultipleLocations, REJECT_TYPE, updateValue,
} from 'components/CustomTable/const'
import { Reject, RejectDetails } from 'objects/types'
import { ReactElement } from 'react'

const colExport: Column[] = [
  {
    propertyName: 'protection_id',
    title: 'protectionId',
    field: 'protection_id',
    align: 'left',
    width: '400px',
    formatter: (o: unknown): string => o as string,
    filterOperators: FILTERED_STRING_OPERATORS,
  },
  {
    propertyName: 'eic_zone',
    title: 'eicZone',
    field: 'eic_zone',
    align: 'left',
    width: '100px',
    formatter: (o: unknown): string => o as string,
    filterOperators: FILTERED_STRING_OPERATORS,
  },
  {
    propertyName: 'locations',
    title: 'lineCode',
    field: 'line_code',
    align: 'left',
    width: '150px',
    filterOperators: FILTERED_STRING_OPERATORS,
    formatter: (_o: unknown, _users: unknown, reject: Reject): ReactElement => {
      return (
        <CustomTextField
          error={checkLocationErrors(reject.id, 'line_code')}
          maxLength={10}
          value={getLocationValue(reject.id, 'line_code') as string}
          onBlur={newValue => {
            if (newValue !== getLocationValue(reject.id, 'line_code') as string) {
              updateValue(['locations', 'line_code'], newValue as string, reject as RejectDetails)
            }
          }}
          endAdornment={hasMultipleLocations(reject as RejectDetails) ? <div>*</div> : <span />}
        />
      )
    },
  },
  {
    propertyName: 'locations',
    title: 'trackName',
    field: 'track_name',
    align: 'left',
    width: '150px',
    filterOperators: FILTERED_STRING_OPERATORS,
    formatter: (_o: unknown, _users: unknown, reject: Reject): ReactElement => {
      return (
        <CustomTextField
          error={checkLocationErrors(reject.id, 'track_name')}
          maxLength={10}
          value={getLocationValue(reject.id, 'track_name') as string}
          onBlur={newValue => {
            if (newValue !== getLocationValue(reject.id, 'track_name') as string) {
              updateValue(['locations', 'track_name'], newValue as string, reject as RejectDetails)
            }
          }}
          endAdornment={hasMultipleLocations(reject as RejectDetails) ? <div>*</div> : <span />}
        />
      )
    },
  },
  {
    propertyName: 'locations',
    title: 'kpStart',
    field: 'kp_start',
    align: 'left',
    width: '150px',
    filterOperators: FILTERED_STRING_OPERATORS,
    formatter: (_o: unknown, _users: unknown, reject: Reject): ReactElement => {
      return (
        <CustomTextField
          error={checkLocationErrors(reject.id, 'kp_start')}
          type="number"
          value={getLocationValue(reject.id, 'kp_start') as string}
          onBlur={newValue => {
            if (newValue !== getLocationValue(reject.id, 'kp_start') as string) {
              updateValue(['locations', 'kp_start'], newValue as string, reject as RejectDetails)
            }
          }}
          endAdornment={hasMultipleLocations(reject as RejectDetails) ? <div>m*</div> : <div>m</div>}
        />
      )
    },
  },
  {
    propertyName: 'locations',
    title: 'kpEnd',
    field: 'kp_end',
    align: 'left',
    width: '150px',
    filterOperators: FILTERED_STRING_OPERATORS,
    formatter: (_o: unknown, _users: unknown, reject: Reject): ReactElement => {
      return (
        <CustomTextField
          error={checkLocationErrors(reject.id, 'kp_end')}
          type="number"
          value={getLocationValue(reject.id, 'kp_end') as string}
          onBlur={newValue => {
            if (newValue !== getLocationValue(reject.id, 'kp_end') as string) {
              updateValue(['locations', 'kp_end'], newValue as string, reject as RejectDetails)
            }
          }}
          endAdornment={hasMultipleLocations(reject as RejectDetails) ? <div>m*</div> : <div>m</div>}
        />
      )
    },
  },
  {
    propertyName: 'protection_name',
    title: 'protectionName',
    field: 'protection_name',
    align: 'left',
    width: '400px',
    formatter: (o: unknown): string => o as string,
    filterOperators: FILTERED_STRING_OPERATORS,
  },
  {
    propertyName: 'reject_type',
    title: 'rejectType',
    field: 'reject_type',
    align: 'left',
    width: '200px',
    formatter: (o: unknown, _users: unknown, reject: Reject): ReactElement => {
      return (
        <CustomSelect
          value={o as string}
          items={REJECT_TYPE}
          onChange={newValue => {
            if (newValue !== o as string) updateValue('reject_type', newValue as string, reject as RejectDetails)
          }}
        />
      )
    },
    filterOperators: FILTERED_STRING_OPERATORS,
  },
  {
    propertyName: 'free_comment',
    title: 'freeComment',
    field: 'free_comment',
    align: 'left',
    width: '300px',
    filterOperators: FILTERED_STRING_OPERATORS,
    formatter: (o: unknown, _users: unknown, reject: Reject): ReactElement => {
      return (
        <CustomTextField
          value={o as string}
          onBlur={newValue => {
            if (newValue !== o as string) {
              updateValue('free_comment', newValue as string, reject as RejectDetails)
            }
          }}
        />
      )
    },
  },
]

export default colExport
