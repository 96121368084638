import { useTranslation } from '@osrdata/app_core/dist/translation'
import { ReactElement } from 'react'
import PriorityHighOutlinedIcon from '@mui/icons-material/PriorityHighOutlined'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined'
import CheckRoundedIcon from '@mui/icons-material/CheckRounded'

import { convertIdToName, formatDate } from 'components/ImportHome/utils'
import { History, RejectTypes } from 'objects/types'
import { GrefState } from 'reducers/grefs'
import { store } from 'Store'

import './HistoryAction.scss'

type Props = {
  history: History;
}

const getDate = (history: History) => (
  <span className="text date d-flex aligne-items-center">{formatDate(history.action_date)}</span>
)

const getItemParams = (history: History, t: (s: string) => string) => {
  const { currentReject, userList } = store.getState().grefs as GrefState
  const week = currentReject.sheet.week.split('-').pop()
  let params = {
    icon: <span />,
    title: '',
    body: <span />,
  }

  switch (history.type) {
    case RejectTypes.reject:
      params = {
        icon: <PriorityHighOutlinedIcon className="icon outlined" />,
        title: 'TreatRejects.rejected.dpReject',
        body: (
          <>
            <span>{` ${t('TreatRejects.rejected.ofDp')}`}</span>
            {getDate(history)}
            <span className="text small-bold">
              {`${week} :\u00A0`}
              <span className="text small-light">{`${currentReject.sheet.serial} `}</span>
            </span>
          </>
        ),
      }
      break
    case RejectTypes.analysis:
      params = {
        title: 'TreatRejects.rejected.analysisHistory',
        icon: <EditOutlinedIcon className="icon blue-background white" />,
        body: (
          <>
            <span>
              {` ${t('TreatRejects.rejected.of')} DP/${currentReject.sheet.serial}/${week} 
            ${t('TreatRejects.rejected.doneBy')} ${convertIdToName(userList, history.action_by)}`}
            </span>
            {getDate(history)}
            {history.action_text && (
              <span className="text small-bold blue">
                {`${t('TreatRejects.table.freeComment')} : `}
                <span className="text small-light blue">
                  {history.action_text}
                </span>
              </span>
            )}
          </>
        ),
      }
      break
    case RejectTypes.export:
      params = {
        title: 'TreatRejects.rejected.csvExport',
        icon: <FileDownloadOutlinedIcon className="icon yellow-background" />,
        body: (
          <>
            <span>{` ${t('TreatRejects.rejected.doneBy')} ${convertIdToName(userList, history.action_by)}`}</span>
            {getDate(history)}
          </>
        ),
      }
      break
    case RejectTypes.reaccord:
      params = {
        title: 'TreatRejects.rejected.reaccord',
        icon: <CheckRoundedIcon className="icon green-background white" />,
        body: (
          <>
            <span>
              {` ${currentReject.sheet.serial} 
            ${t('TreatRejects.rejected.doneBy')} ${convertIdToName(userList, history.action_by)}`}
            </span>
            {getDate(history)}
          </>
        ),
      }
      break
    case RejectTypes.postReject:
      params = {
        title: 'TreatRejects.rejected.postReject',
        icon: <PriorityHighOutlinedIcon className="icon white red-background" />,
        body: (
          <>
            <span>
              {` ${t('TreatRejects.rejected.sheetReaccord')}`}
              {` ${currentReject.sheet.serial} 
              ${t('TreatRejects.rejected.of')} DP/${currentReject.sheet.serial}/${week}`}
            </span>
            {getDate(history)}
          </>
        ),
      }
      break
    default:
      break
  }
  return params
}

export default function HistoryAction({ history }: Props): ReactElement {
  const { t } = useTranslation()

  const params = getItemParams(history, t)

  return (
    <div className="d-flex w-100 action-container">
      {params.icon}
      <div className="text-container">
        <div className="w-100 pl-3">
          <span className="text title">{t(params.title)}</span>
          {params.body}
        </div>
      </div>
    </div>
  )
}
