import {
  FormControl, MenuItem, Select, SelectChangeEvent,
} from '@mui/material'
import { useTranslation } from '@osrdata/app_core/dist/translation'
import { ReactElement, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { EicState, setEic } from 'reducers/eics'
import { resetSheets, toggleDataView } from 'reducers/grefs'
import EicServices from 'services/EicServices'
import { RootState } from 'Store'
import './EicSelect.scss'

export default function EicSelect(): ReactElement {
  const { t } = useTranslation()
  const { eics } = useSelector((state: RootState) => state.eics) as EicState
  const { isLogged } = useSelector((state: RootState) => state.user)
  const { dataView } = useSelector((state: RootState) => state.grefs)
  const dispatch = useDispatch()
  const { currentEic } = useSelector((state: RootState) => state.eics)

  const handleChange = (event: SelectChangeEvent) => {
    dispatch(setEic(event.target.value))
    if (dataView) {
      dispatch(toggleDataView())
    }
    dispatch(resetSheets())
  }

  useEffect(() => {
    if (isLogged) {
      dispatch(EicServices.getAll(currentEic))
    }
  }, [])

  useEffect(() => {
    if (isLogged) {
      dispatch(EicServices.getAll(currentEic))
    }
  }, [isLogged])

  return (
    <FormControl variant="outlined" sx={{ border: 0, minWidth: 'auto' }}>
      {currentEic
        && (
          <Select
            labelId="select-eic"
            id="select-eic"
            value={currentEic}
            onChange={handleChange}
            label={t('TopBar.selectEic')}
          >

            {eics.map(eic => (
              <MenuItem key={eic.slug.toLowerCase()} value={eic.slug.toLowerCase()}>{eic.name}</MenuItem>
            ))}
          </Select>
        )}
    </FormControl>
  )
}
