/* eslint-disable @typescript-eslint/no-explicit-any */
import { get, patch, post } from '@osrdata/app_core/dist/requests'
import { createAsyncThunk } from '@reduxjs/toolkit'
import {
  Export, ExportModif, Gref, GridOrder, Location, Order, RejectDetails, RejectResponse,
  REJECT_WRITABLE_FIELDS, Sheet, SheetInfo, SheetResponse,
} from 'objects/types'
import { GrefState } from 'reducers/grefs'
import { ThunkApiConfig } from 'types'
import filterFields from 'services/utils'
import { GridFilterItem } from '@mui/x-data-grid'
import URI from '../uri'

const getAll = createAsyncThunk<GrefState, any, ThunkApiConfig>(
  'gref/getAll',
  async ({ eic, page }, thunkApi) => {
    try {
      const response = await get(`/apo/${URI.gref}/`, {
        eic: eic || 'all',
        ordering: '-import_date',
        page,
      })
      return {
        grefs: response.results as Gref[],
        ...response,
      }
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const addGref = createAsyncThunk<any, any | any, ThunkApiConfig>(
  'gref/addGref',
  async (object, thunkApi) => {
    try {
      const response = await post(`/apo/${URI.gref}/`, object.formData)
      return response
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

export enum GridOperator {
  contains = 'contains',
  equals = 'equals',
  startsWith = 'startsWith',
  endsWith = 'endsWith',
  is = 'is',
  greaterThan = 'greaterThan',
  lowerThan = 'lowerThan',
  exclude = 'exclude'
}

enum DjangoOperators {
  'istartswith' = '__istartswith',
  'iendswith' = '__iendswith',
  'icontains' = '__icontains',
  'iexact' = '__iexact',
  'lte' = '__lte',
  'gte' = '__gte',
  'exclude' = '__exclude'
}

export interface FilterItem extends GridFilterItem {
  operatorValue: GridOperator;
}

const LOCATION_FIELDS: Array<keyof Location> = ['line_code', 'track_name', 'kp_end', 'kp_start']
const SHEET_FIELDS: Array<keyof Sheet> = ['serial', 'week', 'reaccord', 'date']

const formatColumnField = (columnField: string) => {
  if (LOCATION_FIELDS.includes(columnField as keyof Location)) {
    return `locations__${columnField}`
  }
  if (SHEET_FIELDS.includes(columnField as keyof Sheet)) {
    return `sheet__${columnField}`
  }
  return columnField
}

const formatFilters = (filterItem: FilterItem | undefined) => {
  if (filterItem === undefined) return {}

  const { operatorValue, value, columnField } = filterItem
  switch (operatorValue) {
    case GridOperator.contains:
      return {
        [formatColumnField(columnField) + DjangoOperators.icontains]: value,
      }
    case GridOperator.equals:
      return {
        [formatColumnField(columnField) + DjangoOperators.iexact]: value,
      }
    case GridOperator.startsWith:
      return {
        [formatColumnField(columnField) + DjangoOperators.istartswith]: value,
      }
    case GridOperator.endsWith:
      return {
        [formatColumnField(columnField) + DjangoOperators.iendswith]: value,
      }
    case GridOperator.is:
      return {
        [formatColumnField(columnField) + DjangoOperators.iexact]: value,
      }
    case GridOperator.greaterThan:
      return {
        [formatColumnField(columnField) + DjangoOperators.gte]: value,
      }
    case GridOperator.lowerThan:
      return {
        [formatColumnField(columnField) + DjangoOperators.lte]: value,
      }
    case GridOperator.exclude:
      return {
        [formatColumnField(columnField) + DjangoOperators.exclude]: value,
      }
    default:
      return {}
  }
}

const formatSorter = (columnField: string, direction: Order) => {
  const formatedField = formatColumnField(columnField)
  return direction === 'asc'
    ? formatedField
    : `-${formatedField}`
}

const buildFilterParams = (filters: FilterItem[] | undefined) => {
  let formattedFilters = {}
  filters?.forEach(filter => {
    formattedFilters = { ...formattedFilters, ...formatFilters(filter) }
  })
  return formattedFilters
}

export type GetRejectsParams = {
  eic: string;
  page?: number;
  startDate?: string;
  endDate?: string;
  userModif?: boolean;
  filterItems?: FilterItem[] | undefined;
  sortItem?: GridOrder;
}

const getRejects = createAsyncThunk<RejectResponse, GetRejectsParams, ThunkApiConfig>(
  'gref/getRejects',
  async ({
    eic, page, filterItems, sortItem,
  }, thunkApi) => {
    const params = buildFilterParams(filterItems)
    try {
      const response = await get(`/apo/${URI.gref}/${eic}/reject/`, {
        page: page || 1,
        ordering: sortItem ? formatSorter(sortItem.field, sortItem.sort) : undefined,
        ...params,
      })
      return response
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const getAllRejects = createAsyncThunk<RejectResponse, any, ThunkApiConfig>(
  'gref/getAllRejects',
  async ({ eic, page }, thunkApi) => {
    try {
      const response = await get(`/apo/${URI.gref}/${eic}/reject/`, {
        eic,
        page: page || 1,
        page_size: 10000,
      })
      return response
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

export type GetSheetsParams = {
  eic: string;
  page?: number;
  filterItems?: FilterItem[] | undefined;
  appendSheets?: boolean; // used in reducer
}

const getSheets = createAsyncThunk<SheetResponse, GetSheetsParams, ThunkApiConfig>(
  'gref/getSheets',
  async ({ eic, page, filterItems }, thunkApi) => {
    const params = buildFilterParams(filterItems)
    try {
      const response = await get(`/apo/${URI.gref}/${eic}/sheet/`, {
        page: page || 1,
        ...params,
      })
      return response
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const getReject = createAsyncThunk<RejectDetails, any, ThunkApiConfig>(
  'gref/getReject',
  async ({ eic, id }, thunkApi) => {
    try {
      const response = await get(`/apo/${URI.gref}/${eic}/reject/${id}/`, {})
      return response
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const updateReject = createAsyncThunk<RejectDetails, Partial<RejectDetails>, ThunkApiConfig>(
  'gref/updateReject',
  async (newReject, thunkApi) => {
    const { eic_zone: eicZone, id } = newReject
    const filteredReject = filterFields(newReject, REJECT_WRITABLE_FIELDS)
    try {
      const response = await patch(
        `/apo/${URI.gref}/${eicZone?.toLowerCase()}/reject/${id}/`,
        filteredReject,
      )
      return response
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const getExports = createAsyncThunk<Export, any, ThunkApiConfig>(
  'gref/getExports',
  async ({ eic, page }, thunkApi) => {
    try {
      const response = await get(`/apo/${URI.gref}/export/`, {
        eic,
        ordering: '-export_date',
        page: page || 1,
      })
      return response
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

type UpdateSheetParams = {
  // eslint-disable-next-line camelcase
  eic_zone: string;
  id: string;
  date: string;
  reaccord: boolean;
  rejectId: string;
}

const updateSheet = createAsyncThunk<Partial<Sheet>, UpdateSheetParams, ThunkApiConfig>(
  'gref/updateSheet',
  async (params, thunkApi) => {
    const { eic_zone: eicZone, id } = params
    const filteredReject = filterFields(params, ['reaccord', 'date'])
    try {
      const response = await patch(
        `/apo/${URI.gref}/${eicZone?.toLowerCase()}/sheet/${id}/`,
        filteredReject,
      )
      return response
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const sendExport = createAsyncThunk<ExportModif, any, ThunkApiConfig>(
  'gref/sendExport',
  async (object, thunkApi) => {
    try {
      const response = await post(`/apo/${URI.gref}/export/`, object.formData)
      return response
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const getExportDetails = createAsyncThunk<any, any, ThunkApiConfig>(
  'gref/getExportDetails',
  async (id, thunkApi) => {
    try {
      const response = await get(`/apo/${URI.gref}/export/${id}/`, {})
      return response
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const getCsv = createAsyncThunk<string, any, ThunkApiConfig>(
  'gref/getCsv',
  async ({ id, thunkApi }) => {
    try {
      const response = await get(`/apo/${URI.gref}/export/${id}/csv`, {})
      return response
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const getSheet = createAsyncThunk<SheetInfo, any, ThunkApiConfig>(
  'gref/getSheet',
  async ({ eic, id }, thunkApi) => {
    try {
      const response = await get(`/apo/${URI.gref}/${eic.toLowerCase()}/sheet/${id}`, {})
      return response
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const getStatus = createAsyncThunk<any, any, ThunkApiConfig>(
  'gref/getStatus',
  async (id, thunkApi) => {
    try {
      const response = await get(`/apo/${URI.gref}/status/${id}`, {})
      return response
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const GrefServices = {
  getAll,
  addGref,
  getRejects,
  getReject,
  updateReject,
  updateSheet,
  getExports,
  sendExport,
  getCsv,
  getSheet,
  getSheets,
  getAllRejects,
  getExportDetails,
  getStatus,
}

export default GrefServices
