import HistoryAction from 'components/Common/HistoryAction/HistoryAction'
import { RejectDetails } from 'objects/types'
import { ReactElement, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import UserServices from 'services/UserServices'
import './HistoryList.scss'

type Props = {
  rejectDetails: RejectDetails;
}

export default function HistoryList({ rejectDetails }: Props): ReactElement {
  const dispatch = useDispatch()

  useEffect(() => {
    const usersId: string[] = []
    rejectDetails.history.forEach(elem => {
      if (usersId.indexOf(elem.action_by) === -1) {
        usersId.push(elem.action_by)
      }
    })

    dispatch(UserServices.getUsersListInfo(usersId))
  }, [])

  return (
    <div className="history-action-container">
      {rejectDetails.history.map(history => (
        <div className="history-action" key={history.action_date}>
          <HistoryAction history={history} />
        </div>
      ))}
    </div>
  )
}
