import { Pagination } from '@mui/material'

/* eslint-disable camelcase */
export type Gref = {
  id: string;
  file_name: string;
  imported_by: string;
  import_date: string;
  zone: string;
  period: string;
  status: string;
}

export type Eic = {
  name: string;
  slug: string;
}

export type Sheet = {
  id: string;
  previous_dates: string[];
  serial: string;
  date: string;
  week: string;
  reaccord: boolean;
}

export type Location = {
  id?: number;
  line_code?: string;
  track_name?: string;
  kp_start?: string;
  kp_end?: string;
}
export type Reject = {
  id: string;
  sheet: Sheet;
  protection_id: string;
  protection_name: string;
  eic_zone: string;
  reject_type: string;
  error_code: string;
  sheet_rejected: boolean;
  status: string;
  previous_analysis: string;
  analysis_by: string;
  import_date: string;
  locations: Location[];
  free_comment: string;
  libelle_comment: string;
  current_analysis: string;
  error_reason: string;
}

export const REJECT_WRITABLE_FIELDS: Array<keyof RejectDetails> = [
  'locations', 'reject_type', 'free_comment', 'current_analysis',
]

type Pagination = {
  count: string;
  next: string;
  page_size: string;
  previous: string;
}

export enum RejectTypes {
  reject = 'REJECT',
  analysis = 'ANALYSIS',
  export = 'EXPORT',
  reaccord = 'REACCORD',
  postReject = 'POST-REJECT',
}

export type History = {
  id: string;
  type: RejectTypes;
  action_by: string;
  action_date: string;
  action_text: string;
}

export interface RejectResponse extends Pagination {
  results: Reject[];
}

export interface RejectDetails extends Reject {
  history: History[];
}

export type Export = {
  id: string;
  name: string;
  exported_by: string;
  export_date: string;
  period: string;
}
export interface ExportResponse extends Pagination {
  results: Export[];
}

export type ExportModif = {
  id: string;
  saved: boolean;
  rows: Reject[];
}

type SheetReject = {
  id: string;
  protection_name: string;
  protection_id: string;
}
export interface SheetInfo extends Sheet {
  rejects: SheetReject[];
}

export interface SheetResponse extends Pagination {
  results: SheetInfo[];
}

export type SheetIndexes = {
  sheet: number;
  reject: number;
}

export type Order = 'asc' | 'desc' | undefined

export type GridOrder = {
  field: string;
  sort: Order;
}

export type MouseEventAnalysis = {
  reject: Reject;
  rejectsToChange: Reject[];
  mouseUp: boolean;
  mouseDown: boolean;
  y: number;
  direction: 'up' | 'down' | null;
}

export type TmpLocation = {
  [key: string]: Location;
}

export type SheetReaccord = {
  rows: Reject[];
}

export type ImportTask = {
  status: string;
  task_uuid: string;
}
