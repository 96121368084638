import { ReactElement, useEffect, useState } from 'react'
import CloseIcon from '@mui/icons-material/Close'
import DeleteIcon from '@mui/icons-material/Delete'
import CustomTextField from 'components/Common/Inputs/CustomTextField/CustomTextField'
import CustomSelect from 'components/Common/Inputs/CustomSelect/CustomSelect'
import { columnsHeader } from 'components/CustomTable/const'
import { useDispatch, useSelector } from 'react-redux'
import {
  addFilter, GrefState, removeFilter, setFilter, setFilterMenu,
} from 'reducers/grefs'
import { useTranslation } from '@osrdata/app_core/dist/translation'
import { RootState, store } from 'Store'
import { FilterItem, GridOperator } from 'services/GrefServices'
import AddIcon from '@mui/icons-material/Add'
import './FilterSelect.scss'
import { Button, ClickAwayListener, IconButton } from '@mui/material'
import { debounce } from 'lodash'

const ITEMS = (t: (s: string) => string) => columnsHeader.map(col => ({
  label: t(`TreatRejects.table.${col.title}`),
  value: col.field,
}))

const EMPTY_FILTER: FilterItem = {
  id: 0,
  operatorValue: GridOperator.contains,
  columnField: 'protection_id',
  value: '',
}

export enum FilterMode {
  normal = 'normal',
  deleteItems = 'deleteItems'
}

const debouncedFilterUpdate = debounce(
  (newFilter: FilterItem) => store.dispatch(setFilter(newFilter)),
  1000,
)

const debouncedFilterAdd = debounce(
  (newFilter: FilterItem) => store.dispatch(addFilter(newFilter)),
  1000,
)

type Props = {
  mode?: FilterMode;
}
const defaultProps: Props = {
  mode: FilterMode.normal,
}

export default function FilterSelect({ mode }: Props): ReactElement {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { filters } = useSelector((state: RootState) => state.grefs) as GrefState
  const columnItems = ITEMS(t)
  const [select, setSelect] = useState(false)
  const [filterList, setFilterList] = useState<FilterItem[]>(filters)

  useEffect(() => {
    if (filters.length === 0) {
      const newEmptyFilter = { ...EMPTY_FILTER }
      setFilterList([newEmptyFilter])
    }
  }, [])

  const handleChange = (key: keyof FilterItem, value: string, id: string | number | undefined) => {
    const filter = { ...filterList.find(elem => elem.id === id) } as FilterItem
    filter[key] = value
    const newState = filterList.map(obj => (
      obj.id === id ? { ...filter } : obj
    ))
    setFilterList(newState)

    if (filter?.value === '') {
      return
    }
    const newFilter = { ...filters.find(filt => filt.id === id) } as FilterItem
    if (!Object.keys(newFilter).length) {
      if (filter !== undefined) {
        debouncedFilterAdd(filter)
      }
    } else {
      debouncedFilterUpdate(filter)
    }
  }

  const handleAddFilter = () => {
    const newEmptyFilter = { ...EMPTY_FILTER }
    if (filters.length) {
      if ((Number(filterList[filterList.length - 1].id)) > (Number(filters[filters.length - 1].id))) {
        newEmptyFilter.id = Number(filterList[filterList.length - 1].id) + 1
      } else {
        newEmptyFilter.id = Number(filters[filters.length - 1].id) + 1
      }
    } else {
      newEmptyFilter.id = Number(filterList[filterList.length - 1].id) + 1
    }
    setFilterList([...filterList, newEmptyFilter])
  }

  const handleClickAway = () => {
    if (!select) {
      filters.forEach(f => {
        if (f.value === undefined || f.value === '') {
          dispatch(removeFilter(f))
        }
      })
      dispatch(setFilterMenu(false))
    }
  }

  const handleOpen = () => {
    setSelect(true)
  }

  const handleClose = () => {
    setSelect(false)
  }

  const handleRemoveFilter = (filter: FilterItem) => {
    if (filters.findIndex(elem => elem.id === filter.id) === -1) {
      setFilterList(list => list.filter(elem => elem.id !== filter.id))
    } else {
      setFilterList(list => list.filter(elem => elem.id !== filter.id))
      if (filters.length === 1) {
        dispatch(setFilterMenu(false))
      }
      dispatch(removeFilter(filter))
    }
  }

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <div className="d-flex justify-content-center" id="filter-select">
        {mode === FilterMode.normal && (
          <div className="close-button">
            <IconButton
              onClick={handleClickAway}
            >
              <CloseIcon />
            </IconButton>
          </div>
        )}
        {filterList?.map(filter => (
          <div key={filter.id} className="d-flex justify-content-center align-items-end">
            {mode === FilterMode.deleteItems && (
              <IconButton
                onClick={() => handleRemoveFilter(filter)}
              >
                <DeleteIcon />
              </IconButton>
            )}
            <div className="ml-3 param">
              <div className="input-title">Colonne</div>
              <CustomSelect
                onOpen={handleOpen}
                onBlur={handleClose}
                items={columnItems}
                value={filter ? (filter.columnField) : columnItems[0].label}
                onChange={newValue => handleChange('columnField', newValue, filter.id)}
              />
            </div>
            <div className="ml-3 param">
              <div className="input-title">Opérateur</div>
              <CustomSelect
                onOpen={handleOpen}
                onBlur={handleClose}
                items={Object.keys(GridOperator).map(op => ({
                  label: t(`TreatRejects.filters.${op}`),
                  value: op,
                }))}
                value={filter
                  ? filter.operatorValue
                  : ''}
                onChange={newValue => handleChange('operatorValue', newValue, filter.id)}
              />
            </div>
            <div className="mx-3 param">
              <div className="input-title">Valeur</div>
              <CustomTextField
                value={filter ? filter.value : ''}
                onChange={newValue => handleChange('value', newValue, filter.id)}
              />
            </div>
          </div>
        ))}
        <div className="d-flex justify-content-start align-items-start panel-footer ml-2">
          <Button size="small" onClick={handleAddFilter} startIcon={<AddIcon />}>
            {t('TreatRejects.filters.addFilter')}
          </Button>
        </div>
      </div>
    </ClickAwayListener>
  )
}

FilterSelect.defaultProps = defaultProps
