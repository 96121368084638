import { ReactElement } from 'react'
import { ReactComponent as RoundInfoIcon } from 'assets/icons/info.svg'
import { getCSSValue } from 'helpers'

import './InfoPanel.scss'

export enum Severity {
  info = 'info',
  warning = 'warning'
}

type Props = {
  title: string;
  subtitle?: string | undefined;
  severity?: Severity;
} & typeof defaultProps

const defaultProps = {
  severity: Severity.info,
  subtitle: '',
}

const getFillColor = (s: Severity) => (s === Severity.info
  ? getCSSValue('--color-blue-500')
  : getCSSValue('--color-red-500')
)

export default function InfoPanel({ title, subtitle, severity }: Props): ReactElement {
  return (
    <div
      id="info-panel"
      className="w-100 d-flex justify-content-center align-items-center"
    >
      <div className="d-flex flex-column justify-content-center align-items-center container">
        <div>
          <RoundInfoIcon fill={getFillColor(severity)} />
        </div>
        <div className="title">
          {title}
        </div>
        {subtitle.length > 0 && (
          <div className="subtitle">
            {subtitle}
          </div>
        )}
      </div>
    </div>
  )
}

InfoPanel.defaultProps = defaultProps
