import { Chip, SxProps } from '@mui/material'
import { getCSSValue } from 'helpers'
import { ReactElement } from 'react'
import { Status } from '../SheetStatusBadge/const'
import './CustomStatusBadge.scss'

type Props = {
  value: string;
}

const treatStyle: SxProps = {
  height: 20,
  backgroundColor: getCSSValue('--color-reject-treat'),
  fontSize: 10,
  borderRadius: '4px',
  fontFamily: 'Avenir',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '120%',
  color: 'white',
}

const failedStyle: SxProps = {
  height: 20,
  padding: '4px 8px',
  backgroundColor: getCSSValue('--color-reject-failed'),
  fontSize: 10,
  borderRadius: '4px',
  fontFamily: 'Avenir',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '120%',
  color: 'white',
}

const pendingStyle: SxProps = {
  height: 20,
  backgroundColor: getCSSValue('--color-reject-pending'),
  fontSize: 10,
  borderRadius: '4px',
  fontFamily: 'Avenir',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '120%',
  color: 'white',
}
const exportStyle: SxProps = {
  height: 20,
  backgroundColor: getCSSValue('--color-reject-export'),
  fontSize: 10,
  borderRadius: '4px',
  fontFamily: 'Avenir',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '120%',
  color: 'white',
}
const doneStyle: SxProps = {
  height: 20,
  backgroundColor: getCSSValue('--color-reject-done'),
  fontSize: 10,
  borderRadius: '4px',
  fontFamily: 'Avenir',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '120%',
  color: 'white',
}

const ocaStyle: SxProps = {
  height: 20,
  backgroundColor: getCSSValue('--color-reject-oca'),
  fontSize: 10,
  borderRadius: '4px',
  fontFamily: 'Avenir',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '120%',
  color: 'white',
}
export default function CustomStatusBadge({ value }: Props): ReactElement {
  const getCustomStyle = () => {
    switch (value) {
      case Status.treat:
        return treatStyle

      case Status.failed:
        return failedStyle

      case Status.pending:
        return pendingStyle

      case Status.export:
        return exportStyle

      case Status.done:
        return doneStyle

      case Status.oca:
        return ocaStyle

      default:
        return failedStyle
    }
  }
  return (
    <Chip sx={{ ...getCustomStyle() }} className="statusChip" label={value || 'Statut inconnu'} />
  )
}
