import { Sheet } from 'objects/types'
import { ReactElement } from 'react'
import './CellDatesContainer.scss'

type Props = {
  sheet: Sheet;
}
export default function CellDatesContainer({ sheet }: Props): ReactElement {
  const renderDates = () => (
    sheet.previous_dates.map(elem => (
      <span className="dates-span" key={elem}>
        {`${elem}; \u00A0`}
      </span>
    ))
  )

  return (
    <div
      className="dates-container"
      title={sheet.previous_dates.join('\n')}
      style={{
        maxHeight: '68px',
        width: '200px',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        alignItems: 'center',
      }}
    >
      {renderDates()}
    </div>
  )
}
