import { ChangeEvent, ReactElement, ReactNode } from 'react'
import { styled, SxProps } from '@mui/material'
import { getCSSValue } from 'helpers'
import { useTranslation } from '@osrdata/app_core/dist/translation'
import LoadingButton from '@mui/lab/LoadingButton'
import './UploadButton.scss'

export enum ButtonStyle {
  primary = 'primary',
  light = 'light',
}

const Input = styled('input')({
  display: 'none',
})

type Props = {
  title: string;
  style?: ButtonStyle;
  onSelectedFile: (event: ChangeEvent<HTMLInputElement>) => void;
  customStyle?: SxProps;
  disabled?: boolean;
  startIcon?: ReactNode;
} & typeof defaultProps

const defaultProps = {
  style: ButtonStyle.primary,
  customStyle: {} as SxProps,
  disabled: false,
  startIcon: <></>,
}

const primaryStyle: SxProps = {
  backgroundColor: getCSSValue('--color-blue-500'),
  color: getCSSValue('--color-near-white'),
  fontSize: 16,
  paddingTop: '10px',
  maxWidth: '450px',
}

const lightStyle: SxProps = {
  borderRadius: '4px',
  backgroundColor: getCSSValue('--color-white'),
  color: getCSSValue('--color-grey-800'),
  fontSize: 12,
  boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.08), 0px 1px 4px rgba(0, 0, 0, 0.06)',
  ':hover': {
    backgroundColor: getCSSValue('--color-lighter-grey'),
    color: getCSSValue('--color-grey-800'),
  },
}

export default function UploadButton({
  title, style, onSelectedFile, customStyle, disabled, startIcon,
}: Props): ReactElement {
  const { t } = useTranslation()
  const getCustomStyle = () => {
    if (customStyle !== null && Object.keys(customStyle).length !== 0) {
      return customStyle
    }
    if (style) {
      switch (style) {
        case ButtonStyle.light:
          return lightStyle
        case ButtonStyle.primary:
          return primaryStyle
        default:
          break
      }
    }
    return primaryStyle
  }

  return (
    <div className="simple-button">
      <label htmlFor="contained-button-file">
        <Input type="file" id="contained-button-file" disabled={disabled} onChange={onSelectedFile} accept=".csv" />
        <LoadingButton
          component="span"
          variant="contained"
          size="large"
          sx={{
            textTransform: 'none',
            width: '335px',
            boxShadow: 0,
            fontFamily: 'Avenir',
            ...getCustomStyle(),
          }}
          disabled={disabled}
          loading={disabled}
          loadingPosition="start"
          startIcon={startIcon}
        >
          {disabled ? t('importRejects.header.pendingImport') : title}
        </LoadingButton>
      </label>
    </div>
  )
}

UploadButton.defaultProps = defaultProps
