/* eslint-disable camelcase */
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined'
import LoadingButton from '@mui/lab/LoadingButton'
import {
  Checkbox, FormControlLabel, FormGroup, Pagination, Paper,
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
} from '@mui/material'
import { useTranslation } from '@osrdata/app_core/dist/translation'
import FeedbackSnackBar from 'components/Common/FeedbackSnackBar/FeedbackSnackBar'
import InfoPanel, { Severity } from 'components/Common/InfoPanel/InfoPanel'
import CustomDatePicker from 'components/Common/Inputs/CustomDatePicker/CustomDatePicker'
import Loader from 'components/Common/Loader'
import { Column } from 'components/CustomTable/const'
import { DateTime } from 'luxon'
import { Reject, TmpLocation } from 'objects/types'
import {
  ChangeEvent, ReactElement, useEffect, useRef, useState,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { resetCsv, setTmpLocations } from 'reducers/grefs'
import GrefServices from 'services/GrefServices'
import { User } from 'services/UserServices/cerbereTypes'
import { RootState } from 'Store'
import colExport from './const'
import './ExportPreview.scss'
import { formatDateExport, currentDate, currentDateToMinutes } from './utils'

export default function ExportPreview(): ReactElement {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const tableRef = useRef<HTMLTableElement>(null)
  const [startDate, setStart] = useState<DateTime | null>(currentDate())
  const [endDate, setEnd] = useState<DateTime | null>(currentDate())
  const { currentEic } = useSelector((state: RootState) => state.eics)
  const {
    rejects, newExport, csvContent, previewExportLoading,
  } = useSelector((state: RootState) => state.grefs)
  const userList = useSelector((state: RootState) => state.grefs.userList) as User[]
  const [page, setPage] = useState(1)
  const [userModif, setCheckbox] = useState(true)
  const [exportClicked, setClick] = useState(false)
  const { page_size } = rejects
  const [rows, setRows] = useState<Reject[]>([])
  const pagesNumber = Math.ceil(newExport.rows.length / page_size) || 1

  const buildFormData = (commit: string) => {
    const formData = new FormData()
    const sDate = formatDateExport(startDate)
    const eDate = formatDateExport(endDate)
    const now = formatDateExport(null)
    formData.append('name', `Import pour TDC, ${now}`)
    formData.append('only_modified', String(userModif))
    formData.append('start_date', sDate)
    formData.append('end_date', eDate)
    if (currentEic.toLowerCase() === 'all') {
      formData.append('all_eic', 'true')
    }
    formData.append('eic', currentEic)

    formData.append('commit', commit)
    const object = { formData }
    return object
  }

  useEffect(() => {
    dispatch(resetCsv())
    const data = buildFormData('false')
    dispatch(GrefServices.sendExport(data))
  }, [])

  useEffect(() => {
    const locs: TmpLocation = { 0: {} }
    rows.forEach(elem => {
      locs[elem.id] = { ...elem.locations[0] }
    })
    dispatch(setTmpLocations(locs))
  }, [rows])

  useEffect(() => {
    const data = buildFormData('false')
    dispatch(GrefServices.sendExport(data))
    setPage(1)
  }, [startDate, endDate, userModif])

  useEffect(() => {
    dispatch(GrefServices.getRejects({ eic: currentEic, userModif }))
    const data = buildFormData('false')
    dispatch(GrefServices.sendExport(data))
  }, [currentEic])

  useEffect(() => {
    if (newExport.id) {
      dispatch(GrefServices.getCsv({ id: newExport.id }))
    }
    if (page === 1) {
      const elems = newExport.rows?.slice((page - 1) * page_size, (page - 1) * page_size + page_size)
      setRows(elems)
    }
    setPage(1)
  }, [newExport])

  const downloadFile = () => {
    const filename = `${t('ExportRejects.preview.filename')}, ${currentDateToMinutes()}`
    const url = window.URL.createObjectURL(new Blob([csvContent]))
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', `${filename}.csv`)
    document.body.appendChild(link)
    link.click()
  }

  useEffect(() => {
    if (csvContent !== '' && exportClicked) {
      downloadFile()
      setClick(false)
    }
  }, [exportClicked, csvContent])

  const onStartChange = (newDate: DateTime | null) => {
    setStart(newDate)
  }

  const onEndChange = (newDate: DateTime | null) => {
    setEnd(newDate)
  }

  const handleChangePage = (_event: ChangeEvent<unknown> | null, newPage: number) => {
    if (tableRef.current) {
      tableRef.current.scrollTo(0, 0)
    }
    setPage(newPage)
    const scroller = document.getElementsByClassName('MuiDataGrid-virtualScroller')[0]
    if (scroller) {
      scroller.scrollTo(0, 0)
    }
  }

  useEffect(() => {
    const elems = newExport.rows?.slice((page - 1) * page_size, (page - 1) * page_size + page_size)
    setRows(elems)
  }, [page])

  const onChangeCheckbox = () => {
    setCheckbox(!userModif)
  }

  const handleCsvExport = () => {
    dispatch(resetCsv())
    const data = buildFormData('true')
    setClick(true)
    dispatch(GrefServices.sendExport(data))
  }

  return (
    <div className="d-flex w-100  flex-wrap justify-content-between">
      <div className="d-flex w-100 h-100 export-preview  justify-content-between align-items-center ">
        <div className="d-flex w-100 h-100 align-items-center">
          <span className="date-label">{t('ExportRejects.preview.since')}</span>
          <CustomDatePicker value={startDate} onChange={onStartChange} />

          <span className="date-label">{t('ExportRejects.preview.until')}</span>
          <CustomDatePicker value={endDate} onChange={onEndChange} />

          <FormGroup className="formgroup">
            <FormControlLabel
              className="form-control-checkbox"
              control={<Checkbox onChange={onChangeCheckbox} checked={userModif} />}
              label={t('ExportRejects.preview.onlyOwnEdit')}
            />
            <FormControlLabel
              className="form-control-checkbox"
              control={<Checkbox onChange={onChangeCheckbox} checked={!(userModif)} />}
              label={t('ExportRejects.preview.allEicEdit')}
            />
          </FormGroup>
        </div>
        <div>
          <LoadingButton
            disabled={!newExport.rows.length || previewExportLoading}
            className="button-csv"
            variant="contained"
            onClick={handleCsvExport}
            size="large"
            loading={previewExportLoading && exportClicked}
            loadingPosition="end"
            endIcon={<FileDownloadOutlinedIcon />}
          >
            {previewExportLoading && exportClicked ? 'Export en cours...' : t('ExportRejects.preview.exportToCsv')}
          </LoadingButton>
        </div>

      </div>

      <div style={{
        width: '100%', height: '100vh', backgroundColor: '#F3F7FB',
      }}
      >
        {rows.length > 0 || previewExportLoading ? (
          <Paper elevation={0} className="preview-main-container" square>
            {previewExportLoading ? (
              <div
                style={{ minHeight: '45vh', maxHeight: '65vh' }}
                className=" d-flex justify-content-center align-items-center"
              >
                <Loader />
              </div>
            ) : (
              <>
                <TableContainer
                  ref={tableRef}
                  className="preview-table-container"
                >
                  <Table stickyHeader className="preview-table-content" aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        {colExport.map((headCell: Column) => (
                          <TableCell
                            key={headCell.field}
                            align="left"
                            padding="none"
                            className="export-header-cell"
                            sx={{ minWidth: headCell.width, maxWidth: headCell.width }}
                            title={t(`TreatRejects.table.${headCell.title}`)}
                          >
                            {t(`TreatRejects.table.${headCell.title}`)}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {
                        rows.map((reject: Reject) => (
                          <TableRow
                            hover
                            key={reject.id}
                          >
                            {colExport.map(column => (
                              <TableCell
                                key={`${reject.id}-${column.title}-pinned`}
                                align={column.align}
                                sx={{ minWidth: column.width, maxWidth: column.width }}
                              >
                                {column.formatter(reject[column.propertyName], userList, reject)}
                              </TableCell>
                            ))}
                          </TableRow>
                        ))
                      }
                    </TableBody>
                  </Table>
                </TableContainer>
                <div
                  style={{ backgroundColor: '#F3F7FB' }}
                  className="d-flex footer align-items-end justify-content-center"
                >
                  <Pagination
                    onChange={handleChangePage}
                    variant="outlined"
                    page={page}
                    shape="rounded"
                    boundaryCount={1}
                    siblingCount={1}
                    count={pagesNumber}
                  />
                </div>
              </>
            )}
            <FeedbackSnackBar />
          </Paper>
        ) : (
          <InfoPanel
            title={t('importRejects.noImport.title')}
            severity={Severity.info}
          />
        )}
      </div>
    </div>
  )
}
