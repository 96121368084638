import { Checkbox } from '@mui/material'
import { ChangeEvent, ReactElement, useState } from 'react'

type Props = {
  value: boolean;
  onChange?: (b: boolean) => void;
} & typeof defaultProps

const defaultProps = {
  onChange: (_b: boolean) => {},
}

export default function CustomCheckbox({ value, onChange }: Props): ReactElement {
  const [input, setVal] = useState(value)

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setVal(e.target.checked)
    onChange(e.target.checked)
  }

  return (
    <Checkbox onChange={handleChange} checked={input} />
  )
}

CustomCheckbox.defaultProps = defaultProps
