import { setMessage } from 'reducers/feedback'
import { store } from 'Store'
import { ResponseError } from 'types'

export const DEFAULT_AUTOCLOSE_DURATION = 6000

export enum Severity {
  error = 'error',
  warning = 'warning',
  success = 'success',
  info = 'info',
}

export const handleFeedback = (
  feedback: ResponseError,
  setOpen: (b: boolean) => void,
  setSeverity: (s: Severity) => void,
  setAutoCloseDuration: (n: number | null) => void,
): void => {
  setOpen(true)
  switch (feedback.code) {
    case 0:
      store.dispatch(setMessage(feedback.data.message as string))
      setSeverity(Severity.error)
      setAutoCloseDuration(2000)
      break
    case 200:
      store.dispatch(setMessage(feedback.data.message as string || 'common.updateSuccess'))
      setSeverity(Severity.success)
      setAutoCloseDuration(DEFAULT_AUTOCLOSE_DURATION)
      break
    case 201:
      store.dispatch(setMessage(feedback.data.message as string))
      setSeverity(Severity.success)
      setAutoCloseDuration(2000)
      break
    case 202:
      store.dispatch(setMessage(feedback.data.message as string || 'common.loading'))
      setSeverity(Severity.info)
      setAutoCloseDuration(DEFAULT_AUTOCLOSE_DURATION)
      break
    case 400:
      setSeverity(Severity.error)
      store.dispatch(setMessage(feedback.data.message as string || 'Error.badRequest'))
      break
    case 403:
      store.dispatch(setMessage('Error.forbidden.content'))
      setSeverity(Severity.error)
      setAutoCloseDuration(null)
      break
    case 404:
      store.dispatch(setMessage('Error.notFound'))
      setSeverity(Severity.error)
      setAutoCloseDuration(DEFAULT_AUTOCLOSE_DURATION)
      break
    case 409:
      store.dispatch(setMessage('Error.conflict'))
      setSeverity(Severity.warning)
      setAutoCloseDuration(DEFAULT_AUTOCLOSE_DURATION)
      break
    case 500:
      store.dispatch(setMessage('Error.server'))
      setSeverity(Severity.error)
      setAutoCloseDuration(DEFAULT_AUTOCLOSE_DURATION)
      break
    default:
      store.dispatch(setMessage(feedback.data.message as string || 'Error.unknown'))
      setSeverity(Severity.error)
      setAutoCloseDuration(DEFAULT_AUTOCLOSE_DURATION)
      break
  }
}
