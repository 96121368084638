import { isAnyOf } from '@reduxjs/toolkit'
import GrefServices from 'services/GrefServices'

export const updateErrorMatcher = isAnyOf(
  GrefServices.updateSheet.rejected,
)

export const updateFulfilledMatcher = isAnyOf(
  GrefServices.updateReject.fulfilled,
  GrefServices.updateSheet.fulfilled,
)

export const updatePendingMatcher = isAnyOf(
  GrefServices.updateReject.pending,
  GrefServices.updateSheet.pending,
)
