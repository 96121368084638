import { isAnyOf } from '@reduxjs/toolkit'
import GrefServices from 'services/GrefServices'

export const createErrorMatcher = isAnyOf(
  GrefServices.addGref.rejected,
)

export const createFulfilledMatcher = isAnyOf(
  GrefServices.addGref.fulfilled,
)

export const createPendingMatcher = isAnyOf(
  GrefServices.addGref.pending,
)
