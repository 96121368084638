import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import TableViewIcon from '@mui/icons-material/TableView'
import { useTranslation } from '@osrdata/app_core/dist/translation'
import RejectButton, { ButtonState } from 'components/Common/RejectButton/RejectButton'
import { formatDate } from 'components/ImportHome/utils'
import { Gref } from 'objects/types'
import { ReactElement, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { EicState } from 'reducers/eics'
import { GrefState, resetSheets, toggleDataView } from 'reducers/grefs'
import GrefServices from 'services/GrefServices'
import { RootState } from 'Store'
import './RejectHeader.scss'

export default function RejectHeader(): ReactElement {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { currentEic } = useSelector((state: RootState) => state.eics) as EicState
  const { grefs, dataView } = useSelector((state: RootState) => state.grefs) as GrefState
  const [lastImport, setImport] = useState<Gref>()

  useEffect(() => {
    dispatch(GrefServices.getAll({ eic: currentEic, page: 1 }))
  }, [currentEic])

  useEffect(() => {
    setImport(grefs[0])
  }, [grefs])

  const handleClick = () => {
    if (dataView) {
      dispatch(resetSheets())
    }
    dispatch(toggleDataView())
  }
  return (
    <div className="d-flex w-100 justify-content-between header">
      {dataView
        ? (
          <div className="w-100" />
        ) : (
          <div className="w-100 h-100 justify-content-between align-items-between">
            <h3 className="reject-main-title">{t('TreatRejects.header.lastImport')}</h3>
            <h2 className="reject-date-subtitle">{lastImport && formatDate(lastImport.import_date)}</h2>
          </div>
        )}
      <div className="w-100 d-flex justify-content-center">
        <RejectButton
          startIcon={<TableViewIcon />}
          style={!dataView ? ButtonState.active : ButtonState.inactive}
          title={t('TreatRejects.header.tableView')}
          onClick={handleClick}
        />

        <RejectButton
          startIcon={<EditOutlinedIcon />}
          style={dataView ? ButtonState.active : ButtonState.inactive}
          title={t('TreatRejects.header.dataView')}
          onClick={handleClick}
        />
      </div>

      <div className="w-100" />

    </div>
  )
}
