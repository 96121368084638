import StatusBadge from 'components/Common/StatusBadge/StatusBadge'
import { Gref } from 'objects/types'
import { ReactElement } from 'react'
import { User } from 'services/UserServices/cerbereTypes'
import { convertIdToName, formatDate } from './utils'

type Formatter<T, P, R, S> = (t: T, p: R, s: S) => P

type Column = {
  title: string;
  propertyName: keyof Gref;
  align: 'inherit' | 'left' | 'center' | 'right' | 'justify';
  formatter: Formatter<unknown, string | ReactElement | string, User[], number>;
}

export const COLUMNS: Column[] = [
  {
    title: 'importRejects.table.filename',
    propertyName: 'file_name',
    align: 'left',
    formatter: (o): string => o as string,
  },
  {
    title: 'importRejects.table.importedBy',
    propertyName: 'imported_by',
    align: 'center',
    formatter: (userId: unknown, usersList: User[]): string => (
      convertIdToName(usersList, userId as string)
    ),
  },
  {
    title: 'importRejects.table.importDate',
    propertyName: 'import_date',
    align: 'center',
    formatter: (date: unknown): string => (
      formatDate(date as string)
    ),
  },
  {
    title: 'importRejects.table.zone',
    propertyName: 'zone',
    align: 'center',
    formatter: (o): string => o as string,
  },
  {
    title: 'importRejects.table.period',
    propertyName: 'period',
    align: 'left',
    formatter: (o): string => o as string,
  },
  {
    title: 'importRejects.table.status',
    propertyName: 'status',
    align: 'left',
    formatter: (status: unknown, _users: unknown, page: unknown): ReactElement => (
      <StatusBadge status={status as string} page={page as number} />
    ),

  },
]
export const ROWSELECT: number[] = [5, 10, 15]
