import SimpleButton from 'components/Common/SimpleButton/SimpleButton'
import { ReactElement } from 'react'

import { ReactComponent as WarningTriangle } from 'assets/icons/warning_triangle.svg'

import './AccessDenied.scss'
import { useTranslation } from '@osrdata/app_core/dist/translation'

export default function AccessDenied(): ReactElement {
  const { t } = useTranslation()
  return (
    <div
      id="access-denied"
      className="d-flex w-100 justify-content-center align-items-center"
    >
      <div className="d-flex h-50 w-50 justify-content-center align-items-center text-center flex-column">
        <WarningTriangle className="warning-icon" />
        <span className="main-message">
          {t('Error.forbidden.appTitle')}
        </span>
        <br />
        {t('Error.forbidden.appSubtitle')}
      </div>
      <div className="button-wrapper">
        <SimpleButton
          title={t('Error.forbidden.exitButton')}
          onClick={() => window.location.replace('https://dgexsol.fr')}
        />
      </div>
    </div>
  )
}
