/* eslint-disable arrow-body-style */
import {
  GridColDef, GridRenderCellParams, GridValueFormatterParams, GridValueGetterParams,
} from '@mui/x-data-grid'
import CustomCheckbox from 'components/Common/Inputs/CustomCheckbox/CustomCheckbox'
import { formatSimpleDate } from 'components/RejectHome/utils'
import { RejectDetails } from 'objects/types'
import { ReactElement } from 'react'
import GrefServices from 'services/GrefServices'
import { store } from 'Store'

const columnsHeader: GridColDef[] = [
  {
    field: 'serial',
    headerName: 'serial',
    width: 150,
    sortable: false,
    valueGetter: (params: GridValueGetterParams) => (params.row.sheet.serial),
  },
  {
    field: 'week',
    headerName: 'week',
    width: 150,
    sortable: false,
    valueGetter: (params: GridValueGetterParams) => (params.row.sheet.week),
  },
  {
    field: 'date',
    headerName: 'date',
    width: 200,
    sortable: false,
    valueGetter: (params: GridValueGetterParams) => (params.row.sheet.date),
    valueFormatter: (params: GridValueFormatterParams<string>): string => {
      return formatSimpleDate(params.value)
    },
  },
  {
    field: 'reaccord',
    headerName: 'reaccord',
    width: 300,
    sortable: false,
    valueGetter: (params: GridValueGetterParams) => `${params.row.sheet.reaccord}`,
    renderCell: (params: GridRenderCellParams<boolean>): ReactElement => {
      const row = params.row as RejectDetails
      return (
        <CustomCheckbox
          value={row.sheet.reaccord}
          onChange={newValue => {
            store.dispatch(GrefServices.updateSheet({
              eic_zone: row.eic_zone,
              id: row.sheet.id,
              date: row.sheet.date,
              reaccord: newValue,
              rejectId: row.id,
            }))
          }}
        />
      )
    },
  },
]

export default columnsHeader
