import { Badge, IconButton } from '@mui/material'
import { useTranslation } from '@osrdata/app_core/dist/translation'
import { ReactElement } from 'react'
import SyncIcon from '@mui/icons-material/Sync'
import './StatusBadge.scss'
import GrefServices from 'services/GrefServices'
import { useDispatch } from 'react-redux'
import { colors, statusList } from './const'

type Props = {
  status: string;
  page: number;
}

export default function StatusBadge({ status, page }: Props): ReactElement {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const importState = status.toLowerCase()

  const handleClick = (): void => {
    dispatch(GrefServices.getAll({ page }))
  }

  return (
    <div className="d-flex justify-content-start align-items-center">
      <Badge color={colors[importState]} variant="dot" />
      <div className="label-status">
        {t(`importRejects.table.${importState}`)}
      </div>
      {importState === statusList.pending
        ? (
          <IconButton
            title={t('importRejects.table.refreshStatus')}
            size="small"
            onClick={handleClick}
            aria-label="sync"
          >
            <SyncIcon />
          </IconButton>
        ) : null}
    </div>
  )
}
