import { Sheet } from 'objects/types'
import { ReactElement } from 'react'
import { ObjOfStr } from 'types'

type Formatter<T, P> = (t: T) => P

type SheetInformation = {
  label: string;
  fieldName: string;
  formatter: Formatter<unknown, string | ReactElement | string>;
}

const sheetInfo: SheetInformation[] = [
  {
    label: 'date',
    fieldName: 'sheet',
    formatter: (o: unknown): string => {
      let dates = '';
      (o as Sheet).previous_dates.map(elem => {
        dates += `${elem}; `
        return elem
      })
      return dates
    },
  },
  {
    label: 'status',
    fieldName: 'status',
    formatter: (o): string => o as string,
  },
  {
    label: 'errorReason',
    fieldName: 'error_reason',
    formatter: (o): string => o as string,
  },
  {
    label: 'previousAnalysis',
    fieldName: 'previous_analysis',
    formatter: (o): string => o as string,
  },
  {
    label: 'errorCodeFull',
    fieldName: 'error_code',
    formatter: (o): string => o as string,
  },
  {
    label: 'protectionId',
    fieldName: 'protection_id',
    formatter: (o): string => o as string,
  },
  {
    label: 'sheetReject',
    fieldName: 'sheet_rejected',
    formatter: (rejected: unknown): string => (
      rejected ? 'Oui' : 'Non'
    ),
  },
  {
    label: 'week',
    fieldName: 'sheet',
    formatter: (o): string => (o as ObjOfStr).week,
  },
  {
    label: 'rejectDate',
    fieldName: 'sheet',
    formatter: (o): string => (o as ObjOfStr).date,
  },

]

export default sheetInfo
