import { Export } from 'objects/types'
import { ReactElement } from 'react'
import { User } from 'services/UserServices/cerbereTypes'
import { convertIdToName, formatLastUpdate } from './utils'

type Formatter<T, P, R> = (t: T, p: R) => P

type Column = {
  title: string;
  propertyName: keyof Export;
  align: 'inherit' | 'left' | 'center' | 'right' | 'justify';
  formatter: Formatter<unknown, string | ReactElement, User[]>;
}

const COLUMNS: Column[] = [
  {
    title: 'ExportRejects.table.exportHistory',
    propertyName: 'name',
    align: 'left',
    formatter: (o): string => o as string,
  },
  {
    title: 'ExportRejects.table.exportedBy',
    propertyName: 'exported_by',
    align: 'center',
    formatter: (userId: unknown, usersList: User[]): string => (
      convertIdToName(usersList, userId as string)
    ),
  },
  {
    title: 'ExportRejects.table.lastUpdate',
    propertyName: 'export_date',
    align: 'center',
    formatter: (date: unknown): string => (
      formatLastUpdate(date as string)
    ),
  },
  {
    title: 'ExportRejects.table.exportPeriod',
    propertyName: 'period',
    align: 'left',
    formatter: (o): string => o as string,
  },
]

export default COLUMNS
